@import url("https://fonts.googleapis.com/css?family=Audiowide|Poppins:300,400,400i,500,700,800,900");

html,
body {
  font-size: 16px;
  color: #424242;
  font-family: "Poppins", sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  font-weight: 400;
  overflow-x: hidden;
  background-color: #000;

}

/* ....................................
1.1 Reset CSS 
.......................................*/
a {
  transition: all 0.3s;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #096b89;
}

a:active {
  outline: 0 none;
  text-decoration: none;
}

a:focus {
  outline: 0px solid;
  text-decoration: none;
}

p {
  margin: 0 0 26px;
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 26px;
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

input {
  box-shadow: none;
  border-radius: 0;
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

input:active {
  outline: none;
  box-shadow: none;
}

textarea {
  outline: none;
  box-shadow: none;
  border-radius: 0;
}

textarea:focus {
  outline: none;
  box-shadow: none;
}

textarea:active {
  outline: none;
  box-shadow: none;
}

button {
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

button:active {
  outline: none;
  box-shadow: none;
  border: none;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.w-50 {
  width: 50%;
}

::-moz-selection {
  background: #00c3ff;
  text-shadow: none;
}

::selection {
  background: #00c3ff;
  text-shadow: none;
}

.browserupgrade {
  margin: 26px 0;
  background: #00c3ff;
  color: #333333;
  padding: 26px 0;
}

/***Typography***/
h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-36 {
  font-size: 36px;
}

.f-40 {
  font-size: 40px;
}

.f-48 {
  font-size: 48px;
}

.f-60 {
  font-size: 60px;
}

.f-72 {
  font-size: 72px;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.t-u {
  text-transform: uppercase;
}

.t-c {
  text-transform: capitalize;
}

.t-l {
  text-transform: lowercase;
}

/*** Section ***/
.section-heading {
  display: block;
  position: relative;
  text-align: center;
  margin: 0 0 50px;
}

.section-heading h2 {
  display: inline-block;
  position: relative;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  font-size: 36px;
  text-transform: capitalize;
  color: #fff;
  padding: 0 70px;
}

@media only screen and (max-width: 480px) {
  .section-heading h2 {
    font-size: 30px;
    padding: 0;
  }
}

.section-heading h2:after {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 5px;
  width: 50px;
  background: #878244;
  height: 33.2px;
  -webkit-clip-path: polygon(0 0, 100% 0, 65% 100%, 0% 100%);
  clip-path: polygon(35% 0%, 100% 0, 88% 100%, 0% 100%)
}

@media only screen and (max-width: 480px) {
  .section-heading h2:after {
    display: none;
  }
}

.section-heading h2:before {
  content: '';
  position: absolute;
  right: 0;
  top: 5px;
  bottom: 5px;
  width: 50px;
  background: #878244;
  -webkit-clip-path: polygon(35% 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(35% 0, 100% 0, 100% 100%, 0 100%);
}

@media only screen and (max-width: 480px) {
  .section-heading h2:before {
    display: none;
  }
}

.section-heading p {
  display: block;
  margin: 0 auto;
  width: 70%;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .section-heading p {
    width: 100%;
  }
}

.section-heading2 h2 {
  font-family: "Audiowide", cursive;
  font-weight: 400;
  font-size: 36px;
  text-transform: capitalize;
  color: #fff;
  padding-left: 70px;
  position: relative;
}

@media only screen and (max-width: 320px) {
  .section-heading2 h2 {
    font-size: 30px;
  }
}

.section-heading2 h2:after {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 50px;
  bottom: 5px;
  background: #878244;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
}

.section {
  padding: 100px 0;
  /*  background-color: #111;*/

}

@media only screen and (max-width: 992px) {
  .section {
    padding: 75px 0;
  }
}

@media only screen and (max-width: 768px) {
  .section {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .twitter-posts .owl-controls .owl-buttons {

    top: 108% !important;
    left: 0px;
  }
}

.section2 {
  padding: 100px 0 70px;
}

@media only screen and (max-width: 992px) {
  .section2 {
    padding: 75px 0 45px;
  }
}

@media only screen and (max-width: 768px) {
  .section2 {
    padding: 60px 0 30px;
  }
}

.section3 {
  padding: 70px 0 100px;
}

@media only screen and (max-width: 992px) {
  .section3 {
    padding: 45px 0 75px;
  }
}

@media only screen and (max-width: 768px) {
  .section3 {
    padding: 30px 0 60px;
  }
}

.section4 {
  padding-bottom: 100px;
}

@media only screen and (max-width: 992px) {
  .section4 {
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 768px) {
  .section4 {
    padding-bottom: 60px;
  }
}

.section5 {
  padding-top: 100px;
}

@media only screen and (max-width: 992px) {
  .section5 {
    padding-top: 75px;
  }
}

@media only screen and (max-width: 768px) {
  .section5 {
    padding-top: 60px;
  }
}

.table-hover>tbody>tr:hover {
  background-color: #878244;
}

/***Button***/
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
  margin-right: -3px;
  background: #111;
  border: 1px solid #424242;
  color: #fff;
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  z-index: 2;
  margin-left: -1px;
  background: #111;
  border: 1px solid #424242;
  color: #fff;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  background: #111;
  border: 1px solid #424242;
  color: #fff;
}

.btn1 {
  display: inline-block;
  position: relative;
  padding: 0 60px;
  line-height: 50px;
  height: 42px;
  background: #878244;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  cursor: pointer !important;
  text-transform: capitalize;
  -webkit-clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
  clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
}

@media only screen and (max-width: 320px) {
  .btn1 {
    clip-path: none;
    padding: 0 30px;
  }
}

.btn1:hover {
  background: #111;
  color: #fff;
}

.btn2 {
  display: inline-block;
  position: relative;
  padding: 0 60px;
  line-height: 46px;
  height: 50px;
  background: transparent;
  border: 2px solid #878244;
  text-transform: uppercase;
  font-weight: 500;
  color: #878244;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-clip-path: polygon(11% 0%, 100% 0, 88% 100%, 0% 100%);
  clip-path: polygon(11% 0%, 100% 0, 88% 100%, 0% 100%);
  margin-left: -27px;
}

@media only screen and (max-width: 320px) {
  .btn2 {
    clip-path: none;
    margin: 0;
    padding: 0 30px;
  }

}

.btn2:after {
  content: '';
  position: absolute;
  right: 11px;
  top: -5px;
  bottom: -5px;
  width: 2px;
  transform: rotate(27deg);
  background: #878244;
}

@media only screen and (max-width: 320px) {
  .btn2:after {
    display: none;
  }
}

.btn2:hover {
  background: #878244;
  color: #fff;
}

.btn3 {
  display: inline-block;
  color: #878244;
  font-weight: 500;
  text-transform: capitalize;
}

.btn3 i {
  padding-left: 8px;
}

.btn3:hover {
  color: #111;
}

.btn4 {
  display: inline-block;
  position: relative;
  padding: 0 60px;
  line-height: 46px;
  height: 50px;
  background: transparent;
  border: 2px solid #878244;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  text-transform: capitalize;
  -webkit-clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
  clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
}

@media only screen and (max-width: 320px) {
  .btn4 {
    clip-path: none;
    padding: 0 30px;
  }
}

.btn4:after {
  content: '';
  position: absolute;
  right: 11px;
  top: -5px;
  bottom: -5px;
  width: 2px;
  transform: rotate(27deg);
  background: #424242;
}

@media only screen and (max-width: 320px) {
  .btn4:after {
    display: none;
  }
}

.btn4:before {
  content: '';
  position: absolute;
  left: 11px;
  top: -5px;
  bottom: -5px;
  width: 2px;
  transform: rotate(27.8deg);
  background: #424242;
}

@media only screen and (max-width: 320px) {
  .btn4:before {
    display: none;
  }
}

.btn4:hover {
  background: #878244;
  color: #fff;
}

/*** Reset ***/
.login_wrapper #fb_btn {
  background: #5872ab !important;
  border: 1px solid #5872ab
}

.login_wrapper #fb_btn:hover {
  background-color: #39599f !important;
  border-color: #39599f;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.p-re {
  position: relative;
}

.p-ab {
  position: absolute;
}

.af-be:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.af-be:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.af:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.be:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.bg-img {
  position: relative;
  background: url(images/banner2.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background: #000;
  padding-top: 100px;
  background-position: 0 -110px !important;
  height: 630px;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.aligncenter {
  display: block;
  margin: 0 auto 26px;
}

@media only screen and (max-width: 992px) {
  .sm-t-center {
    text-align: center !important;
  }
}

@media only screen and (max-width: 768px) {
  .xs-t-center {
    text-align: center !important;
  }
}

.dbox {
  overflow: hidden;
  z-index: 1;
  vertical-align: middle;
  width: 100%;
}

.dbox .dleft {
  display: inline-block;
  float: left;
  padding-right: 10px;
}

.dbox .dright {
  display: table;
  padding-left: 10px;
}

#scrollUp {
  bottom: 40px;
  right: 30px;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  border-radius: 100%;
  background: #878244;
  color: #fff;
  transition: all 0.3s;
  z-index: 9999 !important;
  opacity: 0.8;
}

#scrollUp:hover {
  background-color: #333;
  color: #fff;
  opacity: 1;
  line-height: 50px;
}

#preloader {
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2147483647;
  width: 100%;
  height: 100%;
}

#status {
  background-position: center center;
  background-repeat: no-repeat;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #254a93;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

/***All plugins Reset***/
/*owl Navigations*/
.products-area .owl-controls {
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  .products-area .owl-controls {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .products-area .owl-controls {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {
  .products-area .owl-controls {
    display: none !important;
  }
}

.products-area .owl-controls .owl-buttons {
  position: absolute;
  left: 15px;
  right: 13px;
  top: 45%;
}

.products-area .owl-controls .owl-buttons div {
  opacity: 1;
  display: block;
  border-radius: 0;
  background: #878244;
  color: #fff;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0;
  position: absolute;
  font-size: 18px;
  transition: all 0.3s;
}

.products-area .owl-controls .owl-buttons div:hover {
  background: #878244;
  color: #fff;
}

.products-area .owl-controls .owl-buttons div.owl-prev {
  left: -73px;
  padding: 0 25px 0 33px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
}

.products-area .owl-controls .owl-buttons div.owl-next {
  right: -74px;
  padding: 0 33px 0 25px;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
}

.events-area .owl-controls {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .events-area .owl-controls {
    display: none !important;
  }

  .btn2 {
    margin-top: 10px;
  }

  .paginations2 ul li a {
    margin-top: 10px;
  }
}

.events-area .owl-controls .owl-buttons {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
}

.events-area .owl-controls .owl-buttons div {
  opacity: 1;
  display: block;
  border-radius: 0;
  background: #878244;
  color: #111;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0;
  position: absolute;
  font-size: 18px;
  transition: all 0.3s;
  padding: 0 28px 0 20px;
}

.events-area .owl-controls .owl-buttons div:hover {
  background: #878244;
  color: #fff;
}

.events-area .owl-controls .owl-buttons div.owl-prev {
  right: -48px;
  top: 0;
  -webkit-clip-path: polygon(0 0, 75% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 75% 0%, 100% 100%, 0% 100%);
}

.events-area .owl-controls .owl-buttons div.owl-next {
  right: -48px;
  top: 35px;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
}

.blog-area .slick-prev {
  opacity: 1;
  display: block;
  border-radius: 0;
  background: #eee;
  color: #111;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0;
  position: absolute;
  font-size: 18px;
  transition: all 0.3s;
  left: -58px;
  top: 45%;
  padding: 0 25px 0 33px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 25% 100%);
  z-index: 999;
}

@media only screen and (max-width: 1200px) {
  .blog-area .slick-prev {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .blog-area .slick-prev {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .blog-area .slick-prev {
    display: none;
  }
}

.blog-area .slick-prev:hover {
  background: #878244;
  color: #fff;
}

.blog-area .slick-next {
  opacity: 1;
  display: block;
  border-radius: 0;
  background: #eee;
  color: #111;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0;
  position: absolute;
  font-size: 18px;
  transition: all 0.3s;
  right: -45px;
  top: 45%;
  padding: 0 33px 0 25px;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
}

@media only screen and (max-width: 1200px) {
  .blog-area .slick-next {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .blog-area .slick-next {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .blog-area .slick-next {
    display: none;
  }
}

.blog-area .slick-next:hover {
  background: #878244;
  color: #fff;
}

.twitter-posts .owl-controls {
  margin: 0;
}

@media only screen and (max-width: 320px) {
  .twitter-posts .owl-controls {
    display: none !important;
  }
}

.twitter-posts .owl-controls .owl-buttons {
  position: absolute;
  right: 13px;
  top: 38%;
}

.twitter-posts .owl-controls .owl-buttons div {
  opacity: 1;
  display: inline-block;
  border-radius: 0;
  background: transparent;
  color: #111;
  height: 50px;
  line-height: 48px;
  border: 1px solid #fff;
  text-align: center;
  margin: 0;
  width: 80px;
  font-size: 18px;
  transition: all 0.3s;
  -webkit-clip-path: polygon(15% 0, 100% 0, 85% 100%, 0% 100%);
  clip-path: polygon(15% 0, 100% 0, 85% 100%, 0% 100%);
  color: #fff;
  border-left: none;
  border-right: none;
  position: relative;
}

.twitter-posts .owl-controls .owl-buttons div:hover {
  background: #fff;
  color: #111;
}

.twitter-posts .owl-controls .owl-buttons div:after {
  content: '';
  position: absolute;
  left: 6px;
  top: -5px;
  bottom: -5px;
  width: 1px;
  background: #fff;
  transform: rotate(12.5deg);
}

.twitter-posts .owl-controls .owl-buttons div.owl-prev {
  margin-right: -15px;
}

.twitter-posts .owl-controls .owl-buttons div.owl-next:before {
  content: '';
  position: absolute;
  right: 7px;
  top: -5px;
  bottom: -5px;
  width: 1px;
  background: #fff;
  transform: rotate(13deg);
}

/*owl Paginations*/
.owl-controls {
  margin: 0;
}

.owl-controls .owl-pagination .owl-page {
  padding: 0 2px;
}

.owl-controls .owl-pagination .owl-page span {
  width: 17px;
  height: 17px;
  background: #f7f7f7;
  opacity: 1;
  position: relative;
  margin: 0 3px;
}

.owl-controls .owl-pagination .owl-page span:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 3px solid #878244;
  border-radius: 100%;
  opacity: 0;
  transition: all 0.3s;
}

.owl-controls .owl-pagination .owl-page.active span {
  background: transparent;
}

.owl-controls .owl-pagination .owl-page.active span:after {
  opacity: 1;
}

/*bx Slider Navigatons*/
@media only screen and (max-width: 768px) {
  .bx-controls .bx-controls-direction {
    display: none;
  }
}

.bx-controls .bx-controls-direction a {
  text-indent: 0;
  color: transparent;
  text-align: center;
  background: #0e0e0e;
  width: 80px;
  height: 50px;
  line-height: 50px;
}

@media only screen and (max-width: 992px) {
  .bx-controls .bx-controls-direction a {
    top: 62%;
  }
}

.bx-controls .bx-controls-direction a:hover {
  background: #878244;
}

.bx-controls .bx-controls-direction a.bx-prev {
  -webkit-clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
  left: 0;
}

.bx-controls .bx-controls-direction a.bx-prev:before {
  content: '\f30a';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  font-family: "Font Awesome 5 Free";
}

.bx-controls .bx-controls-direction a.bx-next {
  -webkit-clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  right: 0;
}

.bx-controls .bx-controls-direction a.bx-next:before {
  content: '\f30b';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  font-family: "Font Awesome 5 Free";
}

.mfp-bottom-bar {
  display: none;
}

.mfp-arrow {
  display: none !important;
}

/*** Margin ***/
.mr-lu {
  margin-left: auto !important;
}

.mr-ru {
  margin-right: auto !important;
}

.t-0 {
  top: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}

.mr-0 {
  margin: 0;
}

.mr-t0 {
  margin-top: 0px;
}

.mr-t5 {
  margin-top: 5px;
}

.mr-t10 {
  margin-top: 10px;
}

.mr-t15 {
  margin-top: 15px;
}

.mr-t20 {
  margin-top: 20px;
}

.mr-t25 {
  margin-top: 25px;
}

.mr-t30 {
  margin-top: 30px;
}

.mr-t35 {
  margin-top: 35px;
}

.mr-t40 {
  margin-top: 40px;
}

.mr-t45 {
  margin-top: 45px;
}

.mr-t50 {
  margin-top: 50px;
}

.mr-t60 {
  margin-top: 60px;
}

.mr-t70 {
  margin-top: 70px;
}

.mr-t80 {
  margin-top: 80px;
}

.mr-t90 {
  margin-top: 90px;
}

.mr-t100 {
  margin-top: 100px;
}

.mr-t110 {
  margin-top: 110px;
}

.mr-t120 {
  margin-top: 120px;
}

.mr-t130 {
  margin-top: 130px;
}

.mr-t140 {
  margin-top: 140px;
}

.mr-t150 {
  margin-top: 150px;
}

.mr-b0 {
  margin-bottom: 0px;
}

.mr-b5 {
  margin-bottom: 5px;
}

.mr-b10 {
  margin-bottom: 10px;
}

.mr-b15 {
  margin-bottom: 15px;
}

.mr-b20 {
  margin-bottom: 20px;
}

.mr-b25 {
  margin-bottom: 25px;
}

.mr-b30 {
  margin-bottom: 30px;
}

.mr-b35 {
  margin-bottom: 35px;
}

.mr-b40 {
  margin-bottom: 40px;
}

.mr-b45 {
  margin-bottom: 45px;
}

.mr-b50 {
  margin-bottom: 50px;
}

.mr-b60 {
  margin-bottom: 60px;
}

.mr-b70 {
  margin-bottom: 70px;
}

.mr-b80 {
  margin-bottom: 80px;
}

.mr-b90 {
  margin-bottom: 90px;
}

.mr-b100 {
  margin-bottom: 100px;
}

.mr-b110 {
  margin-bottom: 110px;
}

.mr-b120 {
  margin-bottom: 120px;
}

.mr-b130 {
  margin-bottom: 130px;
}

.mr-b140 {
  margin-bottom: 140px;
}

.mr-b150 {
  margin-bottom: 150px;
}

.mr-l0 {
  margin-left: 0px;
}

.mr-l5 {
  margin-left: 5px;
}

.mr-l10 {
  margin-left: 10px;
}

.mr-l15 {
  margin-left: 15px;
}

.mr-l20 {
  margin-left: 20px;
}

.mr-l25 {
  margin-left: 25px;
}

.mr-l30 {
  margin-left: 30px;
}

.mr-l35 {
  margin-left: 35px;
}

.mr-l40 {
  margin-left: 40px;
}

.mr-l45 {
  margin-left: 45px;
}

.mr-l50 {
  margin-left: 50px;
}

.mr-l60 {
  margin-left: 60px;
}

.mr-l70 {
  margin-left: 70px;
}

.mr-l80 {
  margin-left: 80px;
}

.mr-l90 {
  margin-left: 90px;
}

.mr-l100 {
  margin-left: 100px;
}

.mr-l110 {
  margin-left: 110px;
}

.mr-l120 {
  margin-left: 120px;
}

.mr-l130 {
  margin-left: 130px;
}

.mr-l140 {
  margin-left: 140px;
}

.mr-l150 {
  margin-left: 150px;
}

.mr-r0 {
  margin-right: 0px;
}

.mr-r5 {
  margin-right: 5px;
}

.mr-r10 {
  margin-right: 10px;
}

.mr-r15 {
  margin-right: 15px;
}

.mr-r20 {
  margin-right: 20px;
}

.mr-r25 {
  margin-right: 25px;
}

.mr-r30 {
  margin-right: 30px;
}

.mr-r35 {
  margin-right: 35px;
}

.mr-r40 {
  margin-right: 40px;
}

.mr-r45 {
  margin-right: 45px;
}

.mr-r50 {
  margin-right: 50px;
}

.mr-r60 {
  margin-right: 60px;
}

.mr-r70 {
  margin-right: 70px;
}

.mr-r80 {
  margin-right: 80px;
}

.mr-r90 {
  margin-right: 90px;
}

.mr-r100 {
  margin-right: 100px;
}

.mr-r110 {
  margin-right: 110px;
}

.mr-r120 {
  margin-right: 120px;
}

.mr-r130 {
  margin-right: 130px;
}

.mr-r140 {
  margin-right: 140px;
}

.mr-r150 {
  margin-right: 150px;
}

.mr-0 {
  margin: 0;
  color: #fff;
}

.mr-5 {
  margin: 5px;
}

.mr-10 {
  margin: 10px;
}

.mr-15 {
  margin: 15px;
}

.mr-20 {
  margin: 20px;
}

.mr-25 {
  margin: 25px;
}

.mr-30 {
  margin: 30px;
}

.mr-35 {
  margin: 35px;
}

.mr-40 {
  margin: 40px;
}

.mr-45 {
  margin: 45px;
}

.mr-50 {
  margin: 50px;
}

.mr-60 {
  margin: 60px;
}

.mr-70 {
  margin: 70px;
}

.mr-80 {
  margin: 80px;
}

.mr-90 {
  margin: 90px;
}

.mr-100 {
  margin: 100px;
}

.mr-110 {
  margin: 110px;
}

.mr-120 {
  margin: 120px;
}

.mr-130 {
  margin: 130px;
}

.mr-140 {
  margin: 140px;
}

.mr-150 {
  margin: 150px;
}

/*** Padding ***/
.pd-0 {
  padding: 0;
}

.pd-t0 {
  padding-top: 0px;
}

.pd-t5 {
  padding-top: 5px;
}

.pd-t10 {
  padding-top: 10px;
}

.pd-t15 {
  padding-top: 15px;
}

.pd-t20 {
  padding-top: 20px;
}

.pd-t25 {
  padding-top: 25px;
}

.pd-t30 {
  padding-top: 30px;
}

.pd-t35 {
  padding-top: 35px;
}

.pd-t40 {
  padding-top: 40px;
}

.pd-t45 {
  padding-top: 45px;
}

.pd-t50 {
  padding-top: 50px;
}

.pd-t60 {
  padding-top: 60px;
}

.pd-t70 {
  padding-top: 70px;
}

.pd-t80 {
  padding-top: 80px;
}

.pd-t90 {
  padding-top: 90px;
}

.pd-t100 {
  padding-top: 100px;
}

.pd-t110 {
  padding-top: 110px;
}

.pd-t120 {
  padding-top: 120px;
}

.pd-t130 {
  padding-top: 130px;
}

.pd-t140 {
  padding-top: 140px;
}

.pd-t150 {
  padding-top: 150px;
}

.pd-b0 {
  padding-bottom: 0px;
}

.pd-b5 {
  padding-bottom: 5px;
}

.pd-b10 {
  padding-bottom: 10px;
}

.pd-b15 {
  padding-bottom: 15px;
}

.pd-b20 {
  padding-bottom: 20px;
}

.pd-b25 {
  padding-bottom: 25px;
}

.pd-b30 {
  padding-bottom: 30px;
}

.pd-b35 {
  padding-bottom: 35px;
}

.pd-b40 {
  padding-bottom: 40px;
}

.pd-b45 {
  padding-bottom: 45px;
}

.pd-b50 {
  padding-bottom: 50px;
}

.pd-b60 {
  padding-bottom: 60px;
}

.pd-b70 {
  padding-bottom: 70px;
}

.pd-b80 {
  padding-bottom: 80px;
}

.pd-b90 {
  padding-bottom: 90px;
}

.pd-b100 {
  padding-bottom: 100px;
}

.pd-b110 {
  padding-bottom: 110px;
}

.pd-b120 {
  padding-bottom: 120px;
}

.pd-b130 {
  padding-bottom: 130px;
}

.pd-b140 {
  padding-bottom: 140px;
}

.pd-b150 {
  padding-bottom: 150px;
}

.pd-l0 {
  padding-left: 0px;
}

.pd-l5 {
  padding-left: 5px;
}

.pd-l10 {
  padding-left: 10px;
}

.pd-l15 {
  padding-left: 15px;
}

.pd-l20 {
  padding-left: 20px;
}

.pd-l25 {
  padding-left: 25px;
}

.pd-l30 {
  padding-left: 30px;
}

.pd-l35 {
  padding-left: 35px;
}

.pd-l40 {
  padding-left: 40px;
}

.pd-l45 {
  padding-left: 45px;
}

.pd-l50 {
  padding-left: 50px;
}

.pd-l60 {
  padding-left: 60px;
}

.pd-l70 {
  padding-left: 70px;
}

.pd-l80 {
  padding-left: 80px;
}

.pd-l90 {
  padding-left: 90px;
}

.pd-l100 {
  padding-left: 100px;
}

.pd-l110 {
  padding-left: 110px;
}

.pd-l120 {
  padding-left: 120px;
}

.pd-l130 {
  padding-left: 130px;
}

.pd-l140 {
  padding-left: 140px;
}

.pd-l150 {
  padding-left: 150px;
}

.pd-r0 {
  padding-right: 0px;
}

.pd-r5 {
  padding-right: 5px;
}

.pd-r10 {
  padding-right: 10px;
}

.pd-r15 {
  padding-right: 15px;
}

.pd-r20 {
  padding-right: 20px;
}

.pd-r25 {
  padding-right: 25px;
}

.pd-r30 {
  padding-right: 30px;
}

.pd-r35 {
  padding-right: 35px;
}

.pd-r40 {
  padding-right: 40px;
}

.pd-r45 {
  padding-right: 45px;
}

.pd-r50 {
  padding-right: 50px;
}

.pd-r60 {
  padding-right: 60px;
}

.pd-r70 {
  padding-right: 70px;
}

.pd-r80 {
  padding-right: 80px;
}

.pd-r90 {
  padding-right: 90px;
}

.pd-r100 {
  padding-right: 100px;
}

.pd-r110 {
  padding-right: 110px;
}

.pd-r120 {
  padding-right: 120px;
}

.pd-r130 {
  padding-right: 130px;
}

.pd-r140 {
  padding-right: 140px;
}

.pd-r150 {
  padding-right: 150px;
}

.pd-0 {
  padding: 0px;
}

.pd-5 {
  padding: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-25 {
  padding: 25px;
}

.pd-30 {
  padding: 30px;
}

.pd-35 {
  padding: 35px;
}

.pd-40 {
  padding: 40px;
}

.pd-45 {
  padding: 45px;
}

.pd-50 {
  padding: 50px;
}

.pd-60 {
  padding: 60px;
}

.pd-70 {
  padding: 70px;
}

.pd-80 {
  padding: 80px;
}

.pd-90 {
  padding: 90px;
}

.pd-100 {
  padding: 100px;
}

.pd-110 {
  padding: 110px;
}

.pd-120 {
  padding: 120px;
}

.pd-130 {
  padding: 130px;
}

.pd-140 {
  padding: 140px;
}

.pd-150 {
  padding: 150px;
}

/*....................................
2. Header area start here
....................................*/
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  border-bottom: 2px solid rgba(255, 255, 255, 0.05);
}

@media only screen and (max-width: 768px) {
  header {
    background: rgba(0, 0, 0, 0.8);
  }
}

header .logo-area {
  position: relative;
  line-height: 175px;
  height: 175px;
}

@media only screen and (max-width: 768px) {
  header .logo-area {
    height: 120px;
    line-height: 120px;
    padding: 15px 0;
  }
}

header .logo-area a {
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 768px) {
  header .logo-area a {
    display: inline;
  }
}

@media only screen and (max-width: 768px) {
  header .logo-area a img {
    display: block;
    height: 100%;
  }
}

header .mobilemenu .mean-container .mean-nav {
  background: #1D1D22;
  margin-top: 78px;
}

header .mobilemenu .mean-container .mean-nav ul li a {
  font-size: 13px;
  padding: 0 5%;
  line-height: 44px;
}

header .mobilemenu .mean-container .mean-nav ul li a.mean-expand {
  padding: 0;
  line-height: 44px;
  height: 44px !important;
  padding: 0 12px !important;
}

header .mobilemenu .mean-container .mean-nav ul li a.mean-expand:hover {
  background: #1D1D22;
}

header .mobilemenu .mean-container .mean-nav ul li a.mean-expand.mean-expand.mean-clicked {
  background: #1D1D22;
}

header .mobilemenu .mean-container .mean-nav ul li a:hover {
  color: #878244;
  background: transparent;
}

header .mobilemenu .mean-container .mean-bar {
  background: inherit;
  padding: 0;
  min-height: 0;
  position: absolute;
  width: auto !important;
  left: 0;
  right: 0;
  top: 21px;
  z-index: 9999;
}

@media only screen and (max-width: 768px) {
  header .mobilemenu .mean-container .mean-bar {
    top: 42px;
  }
}

header .mobilemenu .mean-container a.meanmenu-reveal {
  top: 0;
  right: 25px !important;
  background: rgba(0, 0, 0, 0.4);
  height: 10px;
  padding: 8px 11px 17px;
}

header.header-inner {
  position: relative;
  background: rgba(0, 0, 0, 0.6);
}

header .main-header {
  text-align: right;
  line-height: 175px;
  height: 175px;
}

header .main-header .main-menus {
  display: inline-block;
}

header .main-header .main-menus nav .mamnu li {
  display: inline-block;
  margin-left: -20px;
  position: relative;
}

header .main-header .main-menus nav .mamnu li a {
  display: block;
  line-height: 50px;
  height: 50px;
  padding: 0 40px;
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  background: transparent;
  -webkit-clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
  clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
}

@media only screen and (max-width: 1600px) {
  header .main-header .main-menus nav .mamnu li a {
    padding: 0 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  header .main-header .main-menus nav .mamnu li a {
    padding: 0 15px;
  }
}

header .main-header .main-menus nav .mamnu li a:hover {
  background: #878244;
}

header .main-header .main-menus nav .mamnu li a.active {
  background: #878244;
}

header .main-header .main-menus nav .mamnu li ul {
  width: 200px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 99999;
  text-align: left;
  visibility: hidden;
  opacity: 0;
  transition: all 0.8s;
  transition: all 0.5s;
}

header .main-header .main-menus nav .mamnu li ul li {
  display: block;
  border-bottom: 1px solid #000;
}

header .main-header .main-menus nav .mamnu li ul li a {
  display: block;
  padding: 0 25px;
  line-height: 0;
  height: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
  clip-path: none;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

header .main-header .main-menus nav .mamnu li ul li a:hover {
  -webkit-clip-path: polygon(8% 0%, 100% 0, 92% 100%, 0% 100%);
  clip-path: polygon(8% 0%, 100% 0, 92% 100%, 0% 100%);
}

header .main-header .main-menus nav .mamnu li ul li:last-child {
  border: none;
}

header .main-header .main-menus nav .mamnu li:hover ul {
  opacity: 1;
  visibility: visible;
  left: 20px;
}

header .main-header .main-menus nav .mamnu li:hover ul li a {
  opacity: 1;
  line-height: 40px;
  height: 40px;
  transition: all 0.3s;
}

header .main-header .serach-header {
  display: inline-block;
}

header .main-header .serach-header .searchd {
  background: transparent;
  line-height: 50px;
  height: 50px;
}

header .main-header .serach-header .searchd i {
  color: #878244;
  font-size: 18px;
}

header .main-header .serach-header .searchbox {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -moz-transform: translate(0px, -100%) scale(0, 0);
  -o-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 11000;
}

header .main-header .serach-header .searchbox .close {
  background-color: transparent;
  box-shadow: none;
  color: #fff;
  font-size: 27px;
  font-weight: normal;
  opacity: 1;
  padding: 10px 17px;
  position: fixed;
  right: 15px;
  top: 15px;
}

header .main-header .serach-header .searchbox form input {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: transparent;
  border-color: -moz-use-text-color -moz-use-text-color #fff;
  border-image: none;
  border-style: none none solid;
  border-width: medium medium 1px;
  color: #fff;
  font-size: 28px;
  font-weight: lighter;
  left: 22%;
  margin: 0;
  outline: medium none;
  padding-bottom: 7px;
  padding-left: 0;
  padding-right: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 700px;
  line-height: 60px;
}

header .main-header .serach-header .searchbox form button {
  display: inline-block;
  line-height: 60px;
  height: 60px;
  left: 72%;
  position: absolute;
  top: 55%;
  transform: translate(0%, -55%);
  background: transparent;
  color: #878244;
  font-size: 18px;
}

.mean-container a.meanmenu-reveal {
  position: relative;
}

.mean-container a.meanmenu-reveal:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f0c9";
  color: #ffffff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-size: 24px;
}

.mean-container a.meanmenu-reveal.meanclose:before {
  display: none;
}

header .mobilemenu .mean-container .mean-nav {
  margin-top: 40px;
}

.mean-container a.meanmenu-reveal span {
  opacity: 0;
}

.mean-container a.meanmenu-reveal {
  width: 100%;
  padding: 8px 0px 17px !important;
  text-align: right !important;
}

@media only screen and (max-width: 992px) {
  header .main-header .serach-header .searchbox form button {
    left: 88%;
    top: 58%;
  }
}

header .main-header .serach-header .searchbox.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -moz-transform: translate(0px, 0px) scale(1, 1);
  -o-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  filter: alpha(opacity=100);
}

header .main-header .cart-head {
  display: inline-block;
  padding-right: 35px;
  position: relative;
}

@media only screen and (max-width: 1600px) {
  header .main-header .cart-head {
    padding-right: 15px;
  }
}

header .main-header .cart-head button {
  background: transparent;
  line-height: 50px;
  height: 50px;
  position: relative;
}

header .main-header .cart-head button:after {
  position: absolute;
  top: 5px;
  right: -5px;
  width: 18px;
  height: 18px;
  background: #d13653;
  color: #fff;
  border-radius: 100%;
  font-size: 12px;
  line-height: 18px;
}

header .main-header .cart-head button i {
  color: #878244;
  font-size: 18px;
}

header .main-header .cart-head .nav-shop-cart {
  width: 305px;
  position: absolute;
  right: 0;
  top: 100%;
  line-height: 30px !important;
  z-index: 99999;
  background-color: #111;
  border: 1px solid #424242;
  padding: 20px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
  display: none;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget {
  list-style: none;
  margin: 0;
  padding: 0px;
  line-height: 30px !important;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item {
  display: inline-block;
  position: relative;
  width: 100%;
  border-top: 1px solid #424242;
  padding: 15px;
  padding-left: 0;
  line-height: 30px !important;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item:first-child {
  padding-top: 0;
  margin-top: 0;
  border-top: 0;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item:last-child {
  margin: 0;
  float: inherit;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item a {
  display: block;
  line-height: 26px;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item a .product-name {
  color: #fff;
  font-weight: 400;
  font-size: 1.071em;
  margin: 0;
  text-align: left;
  transition: all 0.3s;
  font-family: "Audiowide", cursive;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item a img {
  border: 1px solid #e4e4e4;
  height: auto;
  margin-right: 13px;
  width: 50px;
  float: left;
  margin-right: 15px;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item a:hover .product-name {
  color: #878244;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item a.remove {
  font-size: 16px;
  color: #d82e2e;
  position: absolute;
  right: 0;
  top: 25px;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item a.remove:hover {
  color: #4b4b4b;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item:first-child a.remove {
  top: 10px;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item .quantity {
  display: block;
  line-height: 16px;
  margin: 0;
  text-align: left;
}

header .main-header .cart-head .nav-shop-cart .product_list_widget .mini_cart_item .Price-amount {
  padding-left: 5px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-family: "Audiowide", cursive;
}

header .main-header .cart-head .nav-shop-cart .total {
  border-top: 1px solid #424242;
  padding-top: 10px;
  position: relative;
  color: #fff;
}

header .main-header .cart-head .nav-shop-cart .total .amount {
  padding-left: 5px;
  position: relative;
  font-family: "Audiowide", cursive;
}

header .main-header .cart-head .nav-shop-cart .buttons {
  margin: 0;
  line-height: 35px !important;
}

header .main-header .cart-head .nav-shop-cart .buttons a {
  padding: 0 25px;
}

header .main-header .cart-head .nav-shop-cart .buttons a.btn2:after {
  right: 6px;
  transform: rotate(17.5deg);
  z-index: 99;
}

header .main-header .cart-head .nav-shop-cart .buttons a.btn1 {
  z-index: 999;
}

header .main-header .sing-in-btn {
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  header .main-header .sing-in-btn {
    display: none;
  }
}

@media (max-width: 991px) {
  header .main-header .main-menus nav .mamnu li a {
    padding: 0 15px;
  }

  .main_news_right_box {
    margin-top: 50px;
  }

}

/*....................................
3. Slider area start here
....................................*/
.slider-area {
  display: block;
  position: relative;
}

.slider_section_overlay {
  position: absolute;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background: rgba(0, 0, 0, 0.4);
}


.slider-area .item-content .item-slider.items2 {
  position: relative;
}

.slider-area .item-content .item-slider.items3 {
  position: relative;
}

.slider-area .item-content .item-slider.items4 {
  position: relative;
}

.slider-area .item-content .item-slider .contents {
  height: 160vh;
  vertical-align: middle;
  display: table-cell;
}

@media only screen and (max-width: 992px) {
  .slider-area .item-content .item-slider .contents {
    height: 100vh;
    padding-top: 160px;
  }
}

@media only screen and (max-width: 768px) {
  .slider-area .item-content .item-slider .contents {
    padding-top: 125px;
  }

  header .main-header {
    line-height: 128px;
    height: 0;
  }
}

.slider-area .item-content .item-slider .contents h2 {
  display: block;
  font-family: "Audiowide", cursive;
  font-size: 50px;
  color: #fff;
  font-weight: 400;
}

@media only screen and (max-width: 1200px) {
  .slider-area .item-content .item-slider .contents h2 {
    margin: 0 0 20px;
  }
}

@media only screen and (max-width: 768px) {
  .slider-area .item-content .item-slider .contents h2 {
    font-size: 36px;
  }
}

@media only screen and (max-width: 320px) {
  .slider-area .item-content .item-slider .contents h2 {
    font-size: 30px;
  }
}

.slider-area .item-content .item-slider .contents p {
  font-size: 18px;
  display: block;
  width: 48%;
  margin: 0 0 35px;
  color: #ececec;
}

@media only screen and (max-width: 1200px) {
  .slider-area .item-content .item-slider .contents p {
    width: 70%;
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 992px) {
  .slider-area .item-content .item-slider .contents p {
    width: 80%;
    margin: 0 0 25px;
  }
}

@media only screen and (max-width: 480px) {
  .slider-area .item-content .item-slider .contents p {
    width: 95%;
  }
}

.slider-area .item-content .item-slider .contents .buttons {
  display: block;
}

.slider-area .item-content .item-slider .contents .buttons a:first-child:after {
  content: '';
  position: absolute;
  right: 11px;
  top: -5px;
  bottom: -5px;
  width: 2px;
  transform: rotate(24deg);
  background: #878244;
}

@media only screen and (max-width: 320px) {
  .slider-area .item-content .item-slider .contents .buttons a:first-child:after {
    display: none;
  }
}

.slider-area .item-content .item-slider .contents .buttons a:last-child {
  color: #fff;
}

@media only screen and (max-width: 320px) {
  .slider-area .item-content .item-slider .contents .buttons a:last-child {
    display: none;
  }
}

.slider-area .bx-wrapper {
  margin: 0;
}

.slider-area .bx-wrapper .bx-viewport {
  padding: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  border: none;
}

.slider-area .item-thumbnail {
  position: absolute;
  left: 0;
  right: 0;
  /* bottom: 0; */
  z-index: 99999;
  border: 1px solid #424242
}

.slider-area .item-thumbnail {
  display: flex;
}

@media only screen and (max-width: 992px) {
  .slider-area .item-thumbnail {
    /* display: none; */
    width: 100%;
    display: block;
    float: left;
    padding: 10px;
  }
}

.slider-area .item-thumbnail a {
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

.slider-area .item-thumbnail a:after {
  content: '';
  position: absolute;
  right: 0;
  top: 20%;
  bottom: 20%;
  width: 2px;
  background: rgba(255, 255, 255, 0.05);
}

.slider-area .item-thumbnail a.active {
  background: rgba(34, 34, 34, 0.4);
}

.slider-area .item-thumbnail a .items .dbox .dleft figure {
  display: block;
}

.slider-area .item-thumbnail a .items .dbox .dleft figure img {
  border-radius: 5px;
  display: block;
}

.slider-area .item-thumbnail a .items .dbox .dright .content {
  padding: 18px 0;
}

.item-thumbnail a .items .dbox .dright .content h3 {
  font-size: 22px;
  color: #fff;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  margin: 0 0 10px;
}

.item-thumbnail a .items .dbox .dright .content p {
  font-family: "Audiowide", cursive;
  font-size: 18px;
  color: #878244;
  margin: 0;
}

/*....................................
4. About area start here
....................................*/
.about-area {
  /*background: url(images/banner/bg-1.jpg);*/
  background-color: #111;

}

.about-area .about-contents p {
  margin: 0;
}

.about-contents {
  color: #fff;
}

.about-area .about-contents blockquote {
  margin: 0;
  border-left: 2px solid #878244;
  font-size: 16px;
  font-style: italic;
  margin: 35px 0;
  color: #878244;
  font-weight: 500;
  padding: 10px 20px;
}

.about-area .about-contents .buttons {
  display: block;
  margin: 50px 0 0;
}

.about-area .about-contents .buttons .btn1 {
  -webkit-clip-path: polygon(12% 0%, 100% 0, 89% 100%, 0% 100%);
  clip-path: polygon(12% 0%, 100% 0, 89% 100%, 0% 100%);
}

@media only screen and (max-width: 320px) {
  .about-area .about-contents .buttons .btn1 {
    clip-path: none;
  }
}

.about-area .about-cata {
  display: block;
  position: relative;
  margin-left: 40px;
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata {
    margin: 50px 0 0;
  }
}

.about-area .about-cata .cata-list {
  height: 160px;
  padding: 45px 65px;
  background: #111;
  margin-top: -2px;
  display: block;
  position: relative;
  width: 100%;
  transition: all 0.3s;
}

@media only screen and (max-width: 320px) {
  .about-area .about-cata .cata-list {
    padding: 40px 30px;
  }
}

.about-area .about-cata .cata-list:hover {
  background: #424242;
}

.about-area .about-cata .cata-list.list-t1 {
  -webkit-clip-path: polygon(0 0, 88% 0, 100% 100%, 12% 100%);
  clip-path: polygon(0 0, 88% 0, 100% 100%, 12% 100%);
  border: 2px solid #424242;
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata .cata-list.list-t1 {
    clip-path: none;
  }

  .about-area .about-cata .cata-list {
    padding: 45px 35px;
  }
}

.about-area .about-cata .cata-list.list-t1:after {
  content: '';
  position: absolute;
  left: 24px;
  top: -8px;
  bottom: -8px;
  width: 2px;
  background: #424242;
  transform: rotate(-17deg);
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata .cata-list.list-t1:after {
    display: none;
  }
}

.about-area .about-cata .cata-list.list-t1:before {
  content: '';
  position: absolute;
  right: 24px;
  top: -8px;
  bottom: -8px;
  width: 2px;
  background: #424242;
  transform: rotate(-17deg);
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata .cata-list.list-t1:before {
    display: none;
  }
}

.about-area .about-cata .cata-list.list-t2 {
  -webkit-clip-path: polygon(12% 0, 100% 0, 88% 100%, 0 100%);
  clip-path: polygon(12% 0, 100% 0, 88% 100%, 0 100%);
  border: 2px solid #424242;
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata .cata-list.list-t2 {
    clip-path: none;
  }
}

.about-area .about-cata .cata-list.list-t2:after {
  content: '';
  position: absolute;
  left: 24px;
  top: -8px;
  bottom: -8px;
  width: 2px;
  background: #424242;
  transform: rotate(17deg);
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata .cata-list.list-t2:after {
    display: none;
  }
}

.about-area .about-cata .cata-list.list-t2:before {
  content: '';
  position: absolute;
  right: 24px;
  top: -8px;
  bottom: -8px;
  width: 2px;
  background: #424242;
  transform: rotate(17deg);
}

@media only screen and (max-width: 992px) {
  .about-area .about-cata .cata-list.list-t2:before {
    display: none;
  }
}

.about-area .about-cata .cata-list .dbox .dleft {
  width: 65%;
  padding-left: 0;
}

.about-area .about-cata .cata-list .dbox .dleft .content h4 {
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  margin: 0 0 10px;
}

.about-area .about-cata .cata-list .dbox .dright {
  width: 34%;
  text-align: right;
  padding-right: 0;
}

.about-area .about-cata .cata-list .dbox .dright .cate-ico img {
  display: inline-block;
}

/*....................................
5. Breadcumb area start here
....................................*/
.breadcumb-area {
  position: relative;
  padding: 250px 0 100px;
}

@media only screen and (max-width: 768px) {
  .breadcumb-area {
    padding: 200px 0 150px;
  }
}

.breadcumb-area:after {
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
}

.breadcumb-area .breadcumb {
  position: relative;
}

.breadcumb-area .breadcumb:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.05);
  z-index: 99;
}

.breadcumb-area .breadcumb .content {
  line-height: 70px;
  height: 70px;
  position: relative;
  z-index: 999;
}

.breadcumb-area .breadcumb .content h2 {
  font-family: "Audiowide", cursive;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
  line-height: 70px;
}

@media only screen and (max-width: 480px) {
  .breadcumb-area .breadcumb .content h2 {
    font-size: 28px;
  }
}

.breadcumb-area .breadcumb .content ul {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 70px;
  bottom: 0;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .breadcumb-area .breadcumb .content ul {
    position: relative;
  }
}

.breadcumb-area .breadcumb .content ul li {
  display: inline-block;
  position: relative;
  padding: 0 8px;
}

.breadcumb-area .breadcumb .content ul li:after {
  content: '>';
  position: absolute;
  right: -7px;
  top: 0;
  bottom: 0;
  line-height: 70px;
  color: #838383;
}

.breadcumb-area .breadcumb .content ul li a {
  color: #fff;
  text-transform: capitalize;
}

.breadcumb-area .breadcumb .content ul li:last-child {
  padding-right: 0;
}

.breadcumb-area .breadcumb .content ul li:last-child a {
  color: #878244;
}

.breadcumb-area .breadcumb .content ul li:last-child:after {
  display: none;
}

/*....................................
6. Banner area start here
....................................*/
.banner-area {
  position: relative;
  margin: 0 0 55px;
  display: block;
}

@media only screen and (max-width: 992px) {
  .banner-area {
    margin: 0 !important;
  }
}

.banner-area:after {
  background: rgba(0, 0, 0, 0.8);
}

.banner-area .banner-img {
  position: absolute;
  z-index: 999;
  top: 50px;
  left: 160px;
}

@media only screen and (max-width: 1600px) {
  .banner-area .banner-img {
    left: -50px;
    right: 50px;
  }
}

.banner-area .banner-img figure {
  position: relative;
  display: block;
  z-index: 999;
}

.banner-area .banner-img figure img {
  width: 100%;
}

.banner-area .banner-con {
  position: relative;
  z-index: 999;
}

.banner-area .banner-con h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: "Audiowide", cursive;
  margin: 0 0 20px;
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .banner-area .banner-con h2 {
    font-size: 30px;
  }
}

.banner-area .banner-con .price {
  display: block;
  margin: 0 0 26px;
}

.banner-area .banner-con .price del {
  font-family: "Audiowide", cursive;
  font-size: 30px;
  color: #525252;
}

@media only screen and (max-width: 480px) {
  .banner-area .banner-con .price del {
    font-size: 24px;
  }
}

.banner-area .banner-con .price strong {
  font-family: "Audiowide", cursive;
  font-size: 30px;
  color: #878244;
  font-weight: 400;
  padding-left: 15px;
}

@media only screen and (max-width: 480px) {
  .banner-area .banner-con .price strong {
    font-size: 24px;
  }
}

.banner-area2 {
  position: relative;
  display: block;
  padding: 250px 0;
}

@media only screen and (max-width: 480px) {
  .banner-area2 {
    padding: 150px 0;
  }
}

.banner-area2:after {
  background: rgba(0, 0, 0, 0.8);
}

.banner-area2 .videos-area {
  position: relative;
  z-index: 999;
  display: block;
}

.banner-area2 .videos-area .video-popups {
  display: block;
  margin: 0 0 45px;
}

.banner-area2 .videos-area .video-popups a {
  display: inline-block;
  height: 115px;
  line-height: 115px;
  color: #fff;
  background: transparent;
  position: relative;
  padding: 0 50px;
  border: 2px solid #878244;
  font-size: 30px;
  -webkit-clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
  clip-path: polygon(20% 0, 100% 0, 80% 100%, 0% 100%);
  transition: all 0.3s;
}

.banner-area2 .videos-area .video-popups a:after {
  content: '';
  position: absolute;
  left: 12px;
  top: -3px;
  bottom: -3px;
  width: 2px;
  background: #878244;
  transform: rotate(13deg);
  transition: all 0.3s;
}

.banner-area2 .videos-area .video-popups a:before {
  content: '';
  position: absolute;
  right: 12px;
  top: -3px;
  bottom: -3px;
  width: 2px;
  background: #878244;
  transform: rotate(13deg);
  transition: all 0.3s;
}

.banner-area2 .videos-area .video-popups a:hover {
  -webkit-clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
  clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
}

.banner-area2 .videos-area .video-popups a:hover:after {
  content: '';
  position: absolute;
  left: 12px;
  top: -3px;
  bottom: -3px;
  width: 2px;
  background: #878244;
  transform: rotate(-13deg);
  transition: all 0.3s;
}

.banner-area2 .videos-area .video-popups a:hover:before {
  content: '';
  position: absolute;
  right: 12px;
  top: -3px;
  bottom: -3px;
  width: 2px;
  background: #878244;
  transform: rotate(-13deg);
  transition: all 0.3s;
}

.banner-area2 .videos-area h2 {
  color: #fff;
}

@media only screen and (max-width: 320px) {
  .banner-area2 .videos-area h2 {
    font-size: 22px;
  }
}

.banner-area2 .lg-text {
  display: block;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  bottom: 50px;
}

.banner-area2 .lg-text h1 {
  margin: 0;
  font-family: "Audiowide", cursive;
  font-size: 200px;
  color: #fff;
  opacity: 0.05;
}

@media only screen and (max-width: 1600px) {
  .banner-area2 .lg-text h1 {
    font-size: 150px;
  }
}

@media only screen and (max-width: 480px) {
  .banner-area2 .lg-text h1 {
    font-size: 72px;
  }
}

.banner-area3 {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.banner-area3:after {
  background: rgba(0, 0, 0, 0.4);
}

.banner-area3 .content {
  position: relative;
  z-index: 999;
  padding-left: 90px;
}

@media only screen and (max-width: 480px) {
  .banner-area3 .content {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .banner-area3 .content {
    padding-left: 0;
  }
}

.banner-area3 .content:before {
  content: '';
  position: absolute;
  left: 125px;
  top: 0;
  right: -250px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.banner-area3 .content:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 125px;
  background: rgba(0, 0, 0, 0.6);
  -webkit-clip-path: polygon(100% 0, 0 0, 100% 100%);
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  z-index: 99;
}

@media only screen and (max-width: 480px) {
  .banner-area3 .content:after {
    clip-path: none;
  }
}

.banner-area3 .content .con {
  position: relative;
  z-index: 999;
  padding: 80px 0;
  padding-left: 75px;
}

@media only screen and (max-width: 480px) {
  .banner-area3 .content .con {
    padding-left: 40px;
  }
}

.banner-area3 .content h2 {
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  font-family: "Audiowide", cursive;
  position: relative;
}

@media only screen and (max-width: 1600px) {
  .banner-area3 .content h2 {
    font-size: 30px;
  }
}

.banner-area3 .content h2:after {
  content: '';
  position: absolute;
  left: -70px;
  top: 5px;
  width: 50px;
  height: 26px;
  background: #878244;
  -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
}

@media only screen and (max-width: 480px) {
  .banner-area3 .content h2:after {
    display: none;
  }
}

.banner-area3 .content p {
  margin: 0 0 30px;
  color: #fff;
}

.training-area {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  display: block;
}

.training-area:after {
  background: rgba(0, 0, 0, 0.8);
}

.training-area .training-forms {
  position: relative;
  z-index: 999;
  padding: 54px 0;
}

.training-area .training-forms:after {
  content: '';
  position: absolute;
  left: -375px;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.training-area .training-forms form {
  display: block;
  position: relative;
  z-index: 999;
  -webkit-clip-path: polygon(0 0, 75% 0, 97% 100%, 0 100%);
  clip-path: polygon(0 0, 75% 0, 97% 100%, 0 100%);
}

.training-area .training-forms form fieldset {
  width: 100%;
  display: block;
  margin: 0 0 20px;
}

.training-area .training-forms form fieldset input {
  width: 100%;
  display: block;
  line-height: 50px;
  height: 50px;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #fff;
  position: relative;
}

.training-area .training-forms form fieldset input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset input:focus::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset input:focus:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset input:focus:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset select {
  width: 100%;
  display: block;
  line-height: 50px;
  height: 50px;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #fff;
  position: relative;
  cursor: pointer;
}

.training-area .training-forms form fieldset select:focus {
  outline: none;
  background: #333;
}

.training-area .training-forms form fieldset textarea {
  width: 100%;
  display: block;
  height: 90px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #fff;
  position: relative;
}

.training-area .training-forms form fieldset textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  transition: all 0.3s;
}

.training-area .training-forms form fieldset textarea:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset textarea:focus::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset textarea:focus:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset textarea:focus:-moz-placeholder {
  /* Firefox 18- */
  font-size: 13px;
  opacity: 0.8;
}

.training-area .training-forms form fieldset.arrows input {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 2.5% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 2.5% 100%);
}

@media only screen and (max-width: 768px) {
  .training-area .training-forms form fieldset.arrows input {
    clip-path: none;
  }
}

.training-area .training-forms form fieldset.arrows select {
  padding-right: 30px;
  -webkit-clip-path: polygon(0 0, 97% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 97% 0, 100% 100%, 0 100%);
}

@media only screen and (max-width: 768px) {
  .training-area .training-forms form fieldset.arrows select {
    clip-path: none;
  }
}

@media only screen and (max-width: 768px) {
  .training-area .training-forms form fieldset.arrows .col-md-7 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .training-area .training-forms form fieldset.arrows .col-md-7 input {
    margin-top: 20px;
  }
}

.training-area .training-forms form button {
  margin-top: 20px;
}

.training-area .training-forms form button:hover {
  background: #fff;
  color: #111;
}

.training-con p {
  color: #fff;
}

.training-area .training-con {
  position: relative;
  display: block;
  z-index: 999;
}

.training-area .training-con h2 {
  font-size: 36px;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  position: relative;
  display: block;
  padding-left: 70px;
}

@media only screen and (max-width: 320px) {
  .training-area .training-con h2 {
    font-size: 28px;
    padding-left: 0;
  }
}

.training-area .training-con h2:after {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0;
  width: 50px;
  height: 30px;
  background: #878244;
  -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
}

@media only screen and (max-width: 320px) {
  .training-area .training-con h2:after {
    display: none;
  }
}

.training-area .training-con h1 {
  font-family: "Audiowide", cursive;
  color: #878244;
  font-size: 40px;
  font-weight: 400;
}

@media only screen and (max-width: 320px) {
  .training-area .training-con h1 {
    font-size: 30px;
  }
}

.training-area .training-con ul li {
  display: block;
  color: #fff;
  font-size: 18px;
  padding: 8px 0;
}

@media only screen and (max-width: 320px) {
  .training-area .training-con ul li {
    font-size: 16px;
  }
}

.training-area .training-con ul li i {
  padding-right: 10px;
  color: #878244;
}

.subscribe-area .subscribe {
  display: block;
  position: relative;
  background: #878244;
  padding: 55px 0;
  -webkit-clip-path: polygon(4% 0, 100% 0, 95% 100%, 0% 100%);
  clip-path: polygon(4% 0, 100% 0, 95% 100%, 0% 100%);
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: -75px;
  z-index: 999;
}

@media only screen and (max-width: 992px) {
  .subscribe-area .subscribe {
    clip-path: none;
    margin: 0 0 -75px !important;
  }
}

.subscribe-area .subscribe .ico {
  color: #4a4617;
  font-size: 50px;
  display: inline-block;
  line-height: 40px;
  padding-left: 65px;
}

@media only screen and (max-width: 992px) {
  .subscribe-area .subscribe .ico {
    display: none;
  }
}

.subscribe-area .subscribe .conts {
  display: inline-block;
  padding: 0 60px 0 20px;
}

@media only screen and (max-width: 992px) {
  .subscribe-area .subscribe .conts {
    padding: 0;
    display: block;
    text-align: center;
  }
}

.subscribe-area .subscribe .conts h2 {
  color: #fff;
  font-size: 24px;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  margin: 0;
  display: block;
  line-height: 20px;
}

.subscribe-area .subscribe .conts p {
  margin: 0;
  color: #fff;
  opacity: 0.5;
  line-height: 20px;
}

.subscribe-area .subscribe form {
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  .subscribe-area .subscribe form {
    display: block;
    text-align: center;
    margin-top: 30px;
  }
}

.subscribe-area .subscribe form input {
  display: inline-block;
  width: 415px;
  line-height: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 0 20px;
  -webkit-clip-path: polygon(0 0, 100% 0, 94.5% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 94.5% 100%, 0% 100%);
  color: #fff;
}

@media only screen and (max-width: 480px) {
  .subscribe-area .subscribe form input {
    clip-path: none;
    margin: 0 0 15px;
    width: 100%;
  }
}

.subscribe-area .subscribe form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.5);
}

.subscribe-area .subscribe form input::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}

.subscribe-area .subscribe form input:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.5);
}

.subscribe-area .subscribe form input:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.5);
}

.subscribe-area .subscribe form button {
  background: #111;
  color: #fff;
}

.subscribe-area .subscribe form button:hover {
  background: #111;
  color: #fff;
}

.google-map-index {
  position: relative;
  display: block;
}

.google-map-index .map-conts {
  display: block;
  position: absolute;
  background: #878244;
  padding: 40px 80px 40px 40px;
  -webkit-clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  z-index: 999;
  top: 20%;
}

@media only screen and (max-width: 992px) {
  .google-map-index .map-conts {
    position: relative;
    display: block;
    width: 100%;
    clip-path: none;
    text-align: center;
    padding: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .google-map-index .map-conts {
    padding: 40px 20px;
  }
}

.google-map-index .map-conts h1 {
  color: #fff;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  margin: 0 15px;
}

.google-map-index .map-conts ul li {
  display: block;
  padding: 10px 0;
}

.google-map-index .map-conts ul li span {
  color: #fff;
  font-size: 18px;
}

/*....................................
7. Services area start here
....................................*/
/*....................................
8. Team area start here
....................................*/
/*....................................
9. Price list area start here
....................................*/
.price-area .price-lists {
  display: block;
  border: 1px solid #424242;
  background: #111;
  text-align: center;
  padding: 50px 0;
  position: relative;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  border-top: none;
  border-bottom: none;
  transition: all 0.3s;
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists {
    clip-path: none;
    border: 1px solid #eee;
    overflow: hidden;
    vertical-align: middle;
    padding-bottom: 0px;
  }

  .subscribe-area .subscribe form button {
    margin-top: 20px;
  }
}

.price-area .price-lists:after {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: 35px;
  height: 1px;
  background: #424242;
  transform: rotate(-10.4deg);
}

@media only screen and (max-width: 992px) {
  .price-area .price-lists:after {
    transform: rotate(-16.4deg);
  }
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists:after {
    display: none;
  }
}

.price-area .price-lists:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: 35px;
  height: 1px;
  background: #424242;
  transform: rotate(-10.4deg);
}

@media only screen and (max-width: 992px) {
  .price-area .price-lists:before {
    transform: rotate(-16.4deg);
  }
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists:before {
    display: none;
  }
}

.price-area .price-lists .phead {
  position: relative;
  display: block;
  border-bottom: 1px solid #424242;
  height: 200px;
}

.price-area .price-lists .phead .rate {
  position: absolute;
  left: -81%;
  background: #878244;
  right: 0;
  top: 24px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  transform: rotate(-45deg);
}

.price-area .price-lists .phead .rate li {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  padding: 0;
}

.price-area .price-lists .phead h3 {
  display: block;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  margin: 0;
  padding-top: 70px;
}

.price-area .price-lists .phead .prices {
  position: absolute;
  left: 0;
  right: 0;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 2px solid #424242;
  margin: 0 auto;
  bottom: -64px;
  background: #111;
  z-index: 999;
  transition: all 0.3s;
}

.price-area .price-lists .phead .prices strong {
  display: inline-block;
  position: relative;
  font-family: "Audiowide", cursive;
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  padding-left: 10px;
  margin-top: 35px;
  transition: all 0.3s;
}

.price-area .price-lists .phead .prices strong i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 0;
}

.price-area .price-lists .phead .prices span {
  display: block;
  font-size: 12px;
  color: #878244;
  line-height: 20px;
  transition: all 0.3s;
}

.price-area .price-lists .pbody {
  display: block;
  position: relative;
  padding: 95px 0 50px;
}

.price-area .price-lists .pbody ul {
  display: block;
  margin: 0 0 20px;
}

.price-area .price-lists .pbody ul li {
  display: block;
  padding: 10px 0;
  color: #fff;
}

.price-area .price-lists.middel {
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists.middel {
    clip-path: none;
  }
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists.middel {
    clip-path: none;
    border: 1px solid #eee;
  }
}

.price-area .price-lists.middel:after {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  top: 35px;
  height: 1px;
  background: #424242;
  transform: rotate(10.4deg);
}

@media only screen and (max-width: 992px) {
  .price-area .price-lists.middel:after {
    transform: rotate(16.4deg);
  }
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists.middel:after {
    display: none;
  }
}

.price-area .price-lists.middel:before {
  content: '';
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: 35px;
  height: 1px;
  background: #424242;
  transform: rotate(10.4deg);
}

@media only screen and (max-width: 992px) {
  .price-area .price-lists.middel:before {
    transform: rotate(16.4deg);
  }
}

@media only screen and (max-width: 768px) {
  .price-area .price-lists.middel:before {
    display: none;
  }
}

.price-area .price-lists.middel .phead .rate {
  position: absolute;
  left: -76%;
  background: #878244;
  right: 0;
  top: 0;
  height: 36px;
  line-height: 36px;
  text-align: center;
  transform: rotate(-45deg);
}

.price-area .price-lists.middel .phead .rate li {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  padding: 0;
}

.price-area .price-lists:hover {
  border-color: #878244;
}

.price-area .price-lists:hover .phead .prices {
  background: #878244;
}

.price-area .price-lists:hover .phead .prices strong {
  color: #fff;
}

.price-area .price-lists:hover .phead .prices span {
  color: #fff;
}

/*....................................
10. Pagination area start here
....................................*/
.paginations {
  display: block;
  position: relative;
  margin-top: 30px;
  text-align: center;
}

.paginations ul {
  margin-left: -4px;
}

.paginations ul li {
  display: inline-block;
  padding: 0 4px;
}

.paginations ul li a {
  font-size: 16px;
  color: #a5a5a5;
  background: #000;
  height: 45px;
  line-height: 43px;
  width: 45px;
  border: 1px solid #424242;
  display: block;
  text-align: center;
}

.paginations ul li a:hover {
  color: #fff;
  background: #878244;
  border: 1px solid #878244;
}

.paginations ul li a.active {
  color: #fff;
  background: #878244;
  border: 1px solid #424242;
}

.paginations ul li a span {
  color: #878244;
}

.paginations ul li:first-child {
  padding-right: 15px;
}

.paginations ul li:first-child a {
  width: auto;
  padding: 0 15px;
  font-size: 13px;
  text-transform: capitalize;
}

.paginations ul li:first-child a:hover span {
  color: #fff;
}

.paginations ul li:last-child {
  padding-left: 15px;
}

.paginations ul li:last-child a {
  width: auto;
  padding: 0 15px;
  font-size: 13px;
  text-transform: capitalize;
}

.paginations ul li:last-child a:hover span {
  color: #fff;
}

.paginations2 {
  display: block;
  padding-top: 20px;
  width: 100%;
}

.paginations2 ul li a {
  display: block;
  width: 36px;
  height: 36px;
  border: 1px solid #424242;
  text-align: center;
  color: #666666;
  padding: 4px 0;
  color: #fff;
}

.paginations2 ul li a.active {
  background: #878244;
  border: 1px solid #878244;
  color: #fff;
}

.paginations2 ul li a:hover {
  background: #2b2b2b;
  border: 1px solid #2b2b2b;
  color: #fff;
}

.paginations2 ul li a i {
  font-size: 20px;
}

.paginations2 p {
  color: #fff;
  letter-spacing: 1.5px;
  font-weight: 500;
  margin: 0;
}

/*....................................
11. Sidebar area start here
....................................*/
@media only screen and (max-width: 768px) {
  .sibebar {
    margin: 0 0 60px;
    margin-top: 60px;
  }
}

.sibebar .wighet {
  display: block;
  position: relative;
  margin: 0 0 60px;
}

.sibebar .wighet:last-child {
  margin: 0;
}

.sibebar .wighet h3 {
  font-family: "Audiowide", cursive;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  position: relative;
  padding: 0 0 20px;
}

.sibebar .wighet h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  width: 60px;
  background: #424242;
  border-radius: 10px;
}

.sibebar .wighet h3 span {
  color: #878244;
}

.sibebar .search {
  display: block;
  position: relative;
}

.sibebar .search form {
  width: 100%;
  display: block;
  position: relative;
}

.sibebar .search form input {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border: none;
  border-bottom: 1px solid #424242;
  transition: all 0.3s;
  background-color: #111;
  padding-left: 20px;
}

.sibebar .search form input:focus {
  border-bottom: 1px solid #878244;
}

.sibebar .search form button {
  position: absolute;
  top: 0;
  right: 15px;
  line-height: 45px;
  height: 45px;
  color: #878244;
  background: transparent;
}

.sibebar .categories ul li {
  display: block;
  padding: 6px 0;
}

.sibebar .categories ul li:first-child {
  padding-top: 0;
}

.sibebar .categories ul li:last-child {
  padding-bottom: 0;
}

.sibebar .categories ul li a {
  color: #fff;
  display: block;
}

.sibebar .categories ul li a i {
  color: #878244;
  padding-right: 8px;
}

.sibebar .categories ul li a span {
  float: right;
  font-weight: 600;
  color: #fff;
}

.sibebar .categories ul li a:hover {
  color: #878244;
}

.sibebar .post .post-list {
  margin: 0 0 26px;
}

.sibebar .post .post-list:last-child {
  margin: 0;
}

.sibebar .post .post-list .thimb {
  display: inline-block;
  float: left;
  margin-right: 20px;
  width: 90px;
}

.sibebar .post .post-list .thimb a {
  position: relative;
  display: block;
}

.sibebar .post .post-list .thimb a:after {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50%;
  bottom: 50%;
  background: #878244;
  opacity: 0.9;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s;
}

.sibebar .post .post-list .thimb a:before {
  content: '\f002';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  font-size: 14px;
  z-index: 999;
  font-family: FontAwesome;
  text-align: center;
  line-height: 60px;
  transition: all 0.3s;
  opacity: 0;
}

.sibebar .post .post-list .thimb a img {
  width: 100%;
}

.sibebar .post .post-list .thimb a:hover:after {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.sibebar .post .post-list .thimb a:hover:before {
  opacity: 1;
}

.sibebar .post .post-list .con {
  display: table;
}

.sibebar .post .post-list .con span {
  font-size: 14px;
  display: block;
  margin: 0;
  color: #878244;
}

.sibebar .post .post-list .con a {
  color: #111;
  font-family: "Poppins", sans-serif;
}

.sibebar .post .post-list .con a:hover {
  color: #878244;
}

.sibebar .post .post-list .con a h6 {
  font-size: 18px;
  margin: 5px 0 5px;
  font-weight: 400;
  font-family: "Audiowide", cursive;
  color: #fff;
}

.sibebar .tags ul li {
  padding: 5px 3px;
}

.sibebar .tags ul li a {
  display: block;
  border: 1px solid #424242;
  color: #707070;
  font-size: 14px;
  padding: 0 10px;
  border-radius: 5px;
  text-transform: capitalize;
}

.sibebar .tags ul li a:hover {
  background: #878244;
  color: #fff;
  border: 1px solid #878244;
}

.sibebar .price-range {
  display: block;
  padding: 0;
  margin-top: 0 15px;
}

.sibebar .price-range .range strong {
  display: block;
  color: #575560;
  margin: 0 0 20px;
}

.sibebar .price-range .range .range-box {
  margin: 0 0 20px;
}

.sibebar .price-range .range span {
  padding-right: 5px;
  color: #797979;
  display: inline-block;
  text-transform: capitalize;
  margin: 0 0 25px;
}

.sibebar .price-range .range .price-box {
  padding: 4px 0;
  display: inline-block;
  border: none;
  width: 100px;
  color: #fff;
  background: #111;
}

.sibebar .price-range .ui-widget-content .ui-state-default {
  background: #111;
  border-radius: 100%;
  cursor: pointer;
}

.sibebar .price-range .ui-widget-content .ui-state-default:focus {
  outline: none;
}

.sibebar .price-range .ui-widget-content .ui-state-default:last-child {
  background: #111;
  border: 2px solid #111;
}

.sibebar .price-range .ui-widget-header {
  background: #878244;
  height: 5px;
  cursor: pointer;
}

.sibebar .price-range .ui-widget-content {
  background: #f4f4f4;
  height: 5px;
  cursor: pointer;
}

.sibebar .price-range .ui-widget-content {
  border: none;
}

.sibebar .price-range .ui-slider-handle {
  width: 15px;
  height: 15px;
  top: -5px;
}

/*....................................
12. Products area start here
....................................*/
.products-area .pro-ctg {
  margin: 0 0 80px;
}

@media only screen and (max-width: 768px) {
  .products-area .pro-ctg {
    margin: 0 0 50px;
  }
}

.products-area .catagories-lists {
  display: block;
  position: relative;
  width: 100%;
  height: 230px;
  border: 1px solid #424242;
  text-align: center;
  -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
  border-top: none;
  border-bottom: none;
  background: #222;
  transition: all 0.3s;
}

@media only screen and (max-width: 768px) {
  .products-area .catagories-lists {
    clip-path: none;
    border: 1px solid #eee;
  }
}

.products-area .catagories-lists:after {
  content: '';
  position: absolute;
  left: -8px;
  right: -8px;
  top: 12px;
  height: 1px;
  background: #424242;
  transform: rotate(-4.6deg);
  transition: all 0.3s;
}

@media only screen and (max-width: 992px) {
  .products-area .catagories-lists:after {
    transform: rotate(-3.6deg);
  }
}

@media only screen and (max-width: 768px) {
  .products-area .catagories-lists:after {
    display: none;
  }
}

.products-area .catagories-lists:before {
  content: '';
  position: absolute;
  left: -8px;
  right: -8px;
  bottom: 12px;
  height: 1px;
  background: #424242;
  transform: rotate(-4.6deg);
  transition: all 0.3s;
}

@media only screen and (max-width: 992px) {
  .products-area .catagories-lists:before {
    transform: rotate(-3.6deg);
  }

  .paginations ul li a {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .products-area .catagories-lists:before {
    display: none;
  }
}

.products-area .catagories-lists.nd {
  -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
}

@media only screen and (max-width: 768px) {
  .products-area .catagories-lists.nd {
    clip-path: none;
    border: 1px solid #eee;
  }
}

.products-area .catagories-lists.nd:after {
  transform: rotate(4.6deg);
}

@media only screen and (max-width: 992px) {
  .products-area .catagories-lists.nd:after {
    transform: rotate(3.6deg);
  }
}

@media only screen and (max-width: 768px) {
  .products-area .catagories-lists.nd:after {
    display: none;
  }
}

.products-area .catagories-lists.nd:before {
  transform: rotate(4.6deg);
}

@media only screen and (max-width: 992px) {
  .products-area .catagories-lists.nd:before {
    transform: rotate(3.6deg);
  }
}

@media only screen and (max-width: 768px) {
  .products-area .catagories-lists.nd:before {
    display: none;
  }

  .paginations ul li {
    margin-bottom: 10px;
  }
}

.products-area .catagories-lists .contents {
  display: block;
  padding: 75px 0;
}

.products-area .catagories-lists .contents figure {
  display: block;
  margin: 0 0 15px;
}

.products-area .catagories-lists .contents figure img {
  display: inline-block;
}

.products-area .catagories-lists .contents h3 {
  margin: 0;
  font-size: 20px;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s;
}

.products-area .catagories-lists:hover {
  background: #878244;
  border: 1px solid #878244;
}

.products-area .catagories-lists:hover:after {
  background: #878244;
}

.products-area .catagories-lists:hover:before {
  background: #878244;
}

.products-area .catagories-lists:hover .contents h3 {
  color: #fff;
}

.products-area .pro-sliders .products {
  text-align: center;
  display: block;
  position: relative;
}

.products-area .pro-sliders .products figure {
  display: block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.products-area .pro-sliders .products figure img {
  width: 100%;
  transition: all 0.3s;
}

.products-area .pro-sliders .products .contents {
  display: block;
  position: relative;
  border: 1px solid #424242;
  border-top: none;
  padding: 40px 0;
  transition: all 0.3s;
}

.products-area .pro-sliders .products .contents h3 {
  font-size: 22px;
  font-weight: 400;
  font-family: "Audiowide", cursive;
  color: #fff;
  margin: 0 0 10px;
}

.products-area .pro-sliders .products .contents span {
  font-family: "Audiowide", cursive;
  color: #878244;
  display: block;
  font-size: 18px;
  margin: 0 0 20px;
}

.products-area .pro-sliders .products:hover figure img {
  transform: scale(1.1) rotate(5deg);
}

.products-area .pro-sliders .products:hover .contents {
  border: 1px solid #878244;
  border-top: none;
}

.shop-page .filter-area {
  display: block;
  position: relative;
  margin: 0 0 40px;
}

.shop-page .filter-area select {
  display: inline-block;
  width: 230px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #424242;
  padding: 0 15px;
  color: #797979;
  font-size: 14px;
  text-transform: capitalize;
  margin-right: 15px;
  background-color: #111;
}

.shop-page .filter-area .list-grid {
  display: inline-block;
}

@media only screen and (max-width: 320px) {
  .shop-page .filter-area .list-grid {
    margin-top: 10px;
  }
}

.shop-page .filter-area .list-grid ul li {
  padding: 0;
}

.shop-page .filter-area .list-grid ul li a {
  display: block;
  color: #333;
  font-size: 18px;
  height: 50px;
  line-height: 48px;
  width: 50px;
  text-align: center;
  border: 1px solid #424242;
}

.shop-page .filter-area .list-grid ul li a:active,
.shop-page .filter-area .list-grid ul li a:focus {
  background: #878244;
  color: #fff;
  border: 1px solid #878244;
}

.shop-page .filter-area .showpro {
  float: right;
  margin-top: 8px;
}

@media only screen and (max-width: 480px) {
  .shop-page .filter-area .showpro {
    text-align: left;
    margin-top: 10px;
    float: inherit;
  }
}

.shop-page .filter-area .showpro p {
  margin: 0;
  color: #797979;
}

.shop-page .filter-area .showpro p span {
  color: #878244;
}

.shop-page .products {
  text-align: center;
  display: block;
  position: relative;
  margin: 0 0 30px;
}

.shop-page .products figure {
  display: block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}

.shop-page .products figure img {
  width: 100%;
  transition: all 0.3s;
}

.shop-page .products .contents {
  display: block;
  position: relative;
  border: 1px solid #424242;
  border-top: none;
  padding: 40px 0;
  transition: all 0.3s;
}

.shop-page .products .contents h3 {
  font-size: 22px;
  font-weight: 400;
  font-family: "Audiowide", cursive;
  color: #fff;
  margin: 0 0 10px;
}

.shop-page .products .contents p {
  display: none;
}

.shop-page .products .contents span {
  font-family: "Audiowide", cursive;
  color: #878244;
  display: block;
  font-size: 18px;
  margin: 0 0 20px;
}

.shop-page .products:hover figure img {
  transform: scale(1.1) rotate(5deg);
}

.shop-page .products:hover .contents {
  border: 1px solid #878244;
  border-top: none;
}

.shop-page .products.list-item {
  width: 100%;
  display: block;
  overflow: hidden;
  vertical-align: middle;
  text-align: left;
}

.shop-page .products.list-item figure {
  width: 30%;
  display: inline-block;
  float: left;
}

.shop-page .products.list-item .contents {
  display: inline-block;
  width: 70%;
  padding: 21px 30px 0;
  float: left;
  border-bottom: none;
  border-left: none;
}

.shop-page .products.list-item .contents p {
  display: block;
}

.shop-page .products.list-item .contents span {
  display: inline-block;
}

.product-details .products-photo {
  margin-right: 30px;
}

@media only screen and (max-width: 992px) {
  .product-details .products-photo {
    margin: 0 0 40px;
  }
}

.product-details .products-photo .tab-content {
  display: block;
  margin: 0 0 20px;
}

.product-details .products-photo .tab-content .tab-pane img {
  width: 100%;
}

.product-details .products-photo .nav-tabs {
  border: none;
  margin: 0 -10px;
}

.product-details .products-photo .nav-tabs li {
  display: inline-block;
  width: 25%;
  padding: 0 10px;
}

.product-details .products-photo .nav-tabs li a {
  display: block;
  border: none;
  padding: 0;
  border-radius: 0;
}

.product-details .products-photo .nav-tabs li a img {
  width: 100%;
}

.product-details .single-product-content h2 {
  font-size: 36px;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  position: relative;
  display: block;
  padding-left: 70px;
}

.product-details .single-product-content h2:after {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  bottom: 0;
  width: 50px;
  height: 36px;
  background: #878244;
  -webkit-clip-path: polygon(12% 0%, 100% 0, 88% 100%, 0% 100%);
  clip-path: polygon(38% 0%, 100% 0, 62% 100%, 0% 100%);
}

.product-details .single-product-content .product-review {
  display: block;
  margin: 0 0 20px;
}

.product-details .single-product-content .product-review ul {
  display: inline-block;
  padding-right: 10px;
}

.product-details .single-product-content .product-review ul li {
  color: #878244;
  display: inline-block;
}

.product-details .single-product-content .product-review ul li:last-child {
  color: #d4d4d4;
}

.product-details .single-product-content .product-review span {
  display: inline-block;
  color: #797979;
  padding-right: 10px;
}

.product-details .single-product-content .product-review a {
  color: #878244;
}

.product-details .single-product-content .con p {
  margin: 0 0 40px;
}

.product-details .single-product-content .con ul {
  display: block;
  margin: 0 0 40px;
  color: #fff;
}

.product-details .single-product-content .con ul li i {
  color: #878244;
  padding-right: 15px;
}

.product-details .single-product-content .select-pro {
  overflow: hidden;
  vertical-align: middle;
  zoom: 1;
}

.product-details .single-product-content .select-pro .input-group {
  width: 160px;
  border-radius: 0;
  line-height: 43px;
  border: 1px solid #424242;
  float: left;
}

.product-details .single-product-content .select-pro .input-group .btn {
  padding: 0 12px;
  line-height: 45px;
  border-radius: 0;
  background: #111;
  color: #fff;
  border: none;
  font-size: 20px;
}

.product-details .single-product-content .select-pro .input-group .btn:focus {
  outline: none;
}

.product-details .single-product-content .select-pro .input-group input {
  line-height: 45px;
  height: 42px;
  border: none;
  text-align: center;
  background-color: #111;
  color: #fff;
  width: 50%;
}

.product-details .single-product-content .select-pro select {
  width: 130px;
  line-height: 43px;
  height: 45px;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  display: inline-block;
  padding: 0 15px;
  margin-left: 20px;
}

.product-details .single-product-content .price {
  display: block;
  margin: 30px 0;
}

.product-details .single-product-content .price strong {
  font-size: 26px;
  color: #878244;
}

.product-details .single-product-content .price del {
  color: #aaaaaa;
}

.product-details .single-product-content .price span {
  color: #24ce8c;
}

.product-details .single-product-content .buttons a {
  margin-left: 10px;
}

.product-details .single-product-content .buttons a.btn4 {
  border: 1px solid #424242;
}

.product-details .single-product-content .buttons a.btn4:hover {
  border: 1px solid #878244;
}

.product-details .single-product-content .buttons a.heart {
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  color: #333;
  border: 1px solid #424242;
  display: inline-block;
  font-size: 18px;
}

.product-details .single-product-content .buttons a.heart:hover {
  background: #878244;
  border: 1px solid #878244;
  color: #fff;
}

.product-details .pro-details .nav-tabs {
  border-bottom: 5px solid #878244;
}

.nav-tabs>li>a:hover {
  border-color: #eee #eee #ddd;
  background: #878244;
}

.product-details .pro-details .nav-tabs li a {
  font-weight: 700;
  color: #fff;
  border-radius: 0;
  border: none;
  padding: 10px 20px 10px;
  display: inline-block;
}

.product-details .pro-details .nav-tabs li.active a {
  background: #878244;
  color: #fff;
}

.product-details .pro-details .tab-content .tab-pane .con {
  display: block;
  padding: 30px;
  border: 1px solid #424242;
}

.product-details .pro-details .tab-content .tab-pane .con figure {
  display: inline-block;
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
}

.product-details .pro-details .tab-content .tab-pane .con p {
  display: table;
}

.product-details .pro-details .tab-content .tab-pane .con p:nth-child(4) {
  display: inline-block;
}

.product-details .pro-details .tab-content .tab-pane .con ul li {
  display: block;
  color: #fff;
}

.product-details .pro-details .tab-content .tab-pane .con ul li i {
  color: #878244;
  padding-right: 10px;
}

/*....................................
13. Testimonial area start here
....................................*/
/*....................................
14. Client area start here
....................................*/
.partner-area {
  background: #111;
}

.partner-area .partner-list ul {
  overflow: hidden;
  vertical-align: middle;
  padding: 0 0 30px;
}

.partner-area .partner-list ul li {
  display: inline-block;
  width: 20%;
  float: left;
  position: relative;
  margin: 0 0 -30px;
}



@media only screen and (max-width: 992px) {
  .partner-area .partner-list ul li {
    width: 25%;
  }
}

@media only screen and (max-width: 768px) {
  .partner-area .partner-list ul li {
    width: 33.3333%;
  }

  .news_botton_cont p {
    padding: 0 20px 0 0px;
  }
}

@media only screen and (max-width: 480px) {
  .partner-area .partner-list ul li {
    width: 50%;
  }

  .product-details .single-product-content .buttons a {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .product-details .single-product-content .buttons a.heart {
    margin-top: 10px;
  }

  .product-details .pro-details .tab-content .tab-pane .con p {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .partner-area .partner-list ul li {
    width: 100%;
  }
}

.partner-area .partner-list ul li a {
  display: block;
}

.partner-area .partner-list ul li a img {
  width: 100%;
  display: block;
  opacity: 0.3;
  transition: all 0.3s;
}

.partner-area .partner-list ul li a:hover img {
  opacity: 0.1;
}

.subscribe-area {
  float: left;
  width: 100%;
  background: #000;
}

/*....................................
15. Form area start here
....................................*/
/*....................................
16. Social link area start here
....................................*/
.foo-social li {
  display: inline-block;
}

.foo-social li a {
  display: block;
  width: 40px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  -webkit-clip-path: polygon(15% 0, 100% 0, 85% 100%, 0 100%);
  clip-path: polygon(15% 0, 100% 0, 85% 100%, 0 100%);
  background: rgba(255, 255, 255, 0.1);
}

.foo-social li a:hover {
  background: #878244;
}

.twitter-posts {
  display: block;
  position: relative;
  background: #878244;
}

.twitter-posts:after {
  content: '\f099';
  position: absolute;
  left: 1%;
  font-family: "Font Awesome 5 Brands";
  color: #000;
  opacity: 0.08;
  font-size: 180px;
  top: 140px;
}

.twitter-posts .contents {
  position: relative;
  display: block;
}

@media only screen and (max-width: 320px) {
  .twitter-posts .contents {
    text-align: center;
  }
}

.twitter-posts .contents p {
  color: #fff;
}

.twitter-posts .contents a {
  display: inline-block;
  color: #fff;
  padding-left: 10px;
}

.twitter-posts .contents a:hover {
  color: #5DADE2;
}

.twitter-posts .contents span {
  display: block;
  color: #fff;
  opacity: 0.5;
}

.ln-sliders .owl-controls .owl-buttons {
  display: none;
}

/*....................................
17. Blog area start here
....................................*/
.blog-area .blog-slider .blog-lists {
  padding: 0 15px !important;
  margin: 0;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  width: 278px;
  height: 400px;
  background: #fff;
  display: block;
  transition: all 0.3s;
}

@media only screen and (max-width: 480px) {
  .blog-area .blog-slider .blog-lists {
    height: auto;
    display: none;
  }
}

.blog-area .blog-slider .blog-lists:focus {
  outline: none;
}

.blog-area .blog-slider .blog-lists figure {
  display: none;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists figure img {
  display: block;
  width: 100%;
}

.blog-area .blog-slider .blog-lists .contents {
  display: block;
  position: relative;
  border: 1px solid #eee;
  width: 100%;
  padding: 40px;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists .contents .fav {
  display: none;
}

.blog-area .blog-slider .blog-lists .contents .tags {
  display: block;
  margin: 0 0 22px;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists .contents .tags span {
  color: #878244;
}

.blog-area .blog-slider .blog-lists .contents h3 {
  font-size: 22px;
  color: #111;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  margin: 0 0 22px;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists .contents .date {
  font-size: 16px;
  color: #878244;
  border-bottom: 1px solid #eee;
  padding: 0 0 22px;
  display: block;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists .contents .date i {
  font-size: 18px;
  color: #878244;
  padding-right: 8px;
}

.blog-area .blog-slider .blog-lists .contents p {
  font-size: 14px;
  margin: 22px 0;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists.slick-current {
  width: 600px !important;
  height: 400px;
}

@media only screen and (max-width: 480px) {
  .blog-area .blog-slider .blog-lists.slick-current {
    width: 420px !important;
    height: auto;
    display: block;
  }
}

@media only screen and (max-width: 320px) {
  .blog-area .blog-slider .blog-lists.slick-current {
    width: 300px !important;
  }
}

.blog-area .blog-slider .blog-lists.slick-current figure {
  display: block;
  transition: all 0.3s;
}

.blog-area .blog-slider .blog-lists.slick-current figure img {
  display: block;
  width: 100%;
}

.blog-area .blog-slider .blog-lists.slick-current .contents {
  display: block;
  position: absolute;
  border: none;
  width: 100%;
  padding: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s;
}

@media only screen and (max-width: 480px) {
  .blog-area .blog-slider .blog-lists.slick-current .contents {
    top: 50px;
  }

  .news_botton_cont p {
    padding: 0 20px 0 0px;
  }
}

@media only screen and (max-width: 320px) {
  .blog-area .blog-slider .blog-lists.slick-current .contents {
    top: 30px;
  }

  .news_botton_cont p {
    padding: 0 20px 0 0px;
  }
}

.blog-area .blog-slider .blog-lists.slick-current .contents .fav {
  display: inline-block;
  position: absolute;
  top: -220px;
  right: 45px;
  height: 46px;
  line-height: 46px;
  width: 55px;
  border: 1px solid #878244;
  border-left: none;
  border-right: none;
  text-align: center;
  color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
}

@media only screen and (max-width: 480px) {
  .blog-area .blog-slider .blog-lists.slick-current .contents .fav {
    display: none;
  }
}

.blog-area .blog-slider .blog-lists.slick-current .contents .fav:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #878244;
  transform: rotate(6.8deg);
}

.blog-area .blog-slider .blog-lists.slick-current .contents .fav:before {
  content: '';
  position: absolute;
  right: 3px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #878244;
  transform: rotate(6.8deg);
}

.blog-area .blog-slider .blog-lists.slick-current .contents .fav:hover {
  background: #878244;
}

.blog-area .blog-slider .blog-lists.slick-current .contents .tags {
  display: block;
  margin: 0 0 22px;
  transition: all 0.3s;
  position: absolute;
  top: -220px;
}

@media only screen and (max-width: 480px) {
  .blog-area .blog-slider .blog-lists.slick-current .contents .tags {
    display: none;
  }
}

.blog-area .blog-slider .blog-lists.slick-current .contents .tags span {
  color: #878244;
}

.blog-area .blog-slider .blog-lists.slick-current .contents h3 {
  font-size: 22px;
  color: #fff;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  margin: 0 0 22px;
  transition: all 0.3s;
}

@media only screen and (max-width: 320px) {
  .blog-area .blog-slider .blog-lists.slick-current .contents h3 {
    font-size: 20px;
  }
}

.blog-area .blog-slider .blog-lists.slick-current .contents .date {
  font-size: 16px;
  color: #fff;
  border-bottom: none;
  padding: 0 0 22px;
  display: block;
  transition: all 0.3s;
  position: absolute;
  top: 0;
}

.blog-area .blog-slider .blog-lists.slick-current .contents .date i {
  font-size: 18px;
  color: #878244;
  padding-right: 8px;
}

.blog-area .blog-slider .blog-lists.slick-current .contents p {
  font-size: 14px;
  margin: 22px 0;
  transition: all 0.3s;
  display: none;
}

.blog-area .blog-slider .blog-lists.slick-current .contents a:hover {
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .blog-pages .floatright {
    float: inherit;
  }
}

.blog-pages .blog-list {
  display: block;
  margin: 0 0 30px;
}

.blog-pages .blog-list figure {
  position: relative;
  display: block;
  margin: 0;
  overflow: hidden;
  vertical-align: middle;
}

.blog-pages .blog-list figure img {
  display: block;
  width: 100%;
  transition: all 0.5s;
}

.blog-pages .blog-list figure .date {
  position: absolute;
  top: 30px;
  right: 30px;
  background: #878244;
  width: 55px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  padding: 5px 0;
}

.blog-pages .blog-list figure .date strong {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  display: block;
  line-height: 26px;
}

.blog-pages .blog-list figure .date span {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: block;
  text-transform: uppercase;
  line-height: 26px;
}

.blog-pages .blog-list .content {
  border: 1px solid #424242;
  border-top: none;
  display: block;
  padding: 30px;
  transition: all 0.3s;
  background: #111;
}

.blog-pages .blog-list .content h3 {
  font-family: "Audiowide", cursive;
  font-size: 22px;
  color: #fff;
  letter-spacing: 1px;
  margin: 0 0 20px;
}

.blog-pages .blog-list .content p {
  color: #fff;
  font-size: 16px;
  margin: 0 0 20px;
}

.blog-pages .blog-list:hover figure img {
  transform: rotate(5deg) scale(1.2);
}

.blog-pages .blog-list:hover .content {
  border: 1px solid #878244;
  border-top: none;
}

.blog-details .blog-details .blogs {
  display: block;
  margin: 0 0 70px;
  border: 1px solid #424242;
}

@media only screen and (max-width: 768px) {
  .blog-details .blog-details .blogs {
    margin: 0 0 40px;
  }
}

.blog-details .blog-details .blogs figure {
  position: relative;
  display: block;
  overflow: hidden;
  vertical-align: middle;
}

.blog-details .blog-details .blogs figure img {
  display: block;
  width: 100%;
  transition: all 0.3s;
}

.blog-details .blog-details .blogs figure .date {
  position: absolute;
  top: 0;
  right: 20px;
  display: inline-block;
  background: #878244;
  width: 60px;
  text-align: center;
  padding-top: 10px;
  z-index: 999;
}

.blog-details .blog-details .blogs figure .date:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  height: 15px;
  border-top: 15px solid #878244;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
}

.blog-details .blog-details .blogs figure .date span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.blog-details .blog-details .blogs figure .date strong {
  display: block;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  color: #fff;
}

.blog-details .blog-details .blogs .content-post .content {
  display: block;
  padding: 40px;

}

@media only screen and (max-width: 320px) {
  .blog-details .blog-details .blogs .content-post .content {
    padding: 20px;
  }
}

.blog-details .blog-details .blogs .content-post .content ul {
  display: block;
  margin: 0 0 10px;
}

.blog-details .blog-details .blogs .content-post .content ul li {
  display: inline-block;
  padding: 0 15px;
}

@media only screen and (max-width: 320px) {
  .blog-details .blog-details .blogs .content-post .content ul li {
    padding: 0 5px;
  }
}

.blog-details .blog-details .blogs .content-post .content ul li:first-child {
  padding-left: 0;
}

.blog-details .blog-details .blogs .content-post .content ul li:last-child {
  padding-right: 0;
}

.blog-details .blog-details .blogs .content-post .content ul li i {
  color: #878244;
  padding-right: 5px;
}

.blog-details .blog-details .blogs .content-post .content ul li span {
  color: #fff;
  text-transform: capitalize;
}

.blog-details .blog-details .blogs .content-post .content h4 {
  color: #fff;
  font-size: 22px;
  font-family: "Audiowide", cursive;
  font-weight: 500;
}

.blog-details .blog-details .blogs .content-post .testi {
  position: relative;
}

@media only screen and (max-width: 320px) {
  .blog-details .blog-details .blogs .content-post .testi .dbox .dleft {
    display: none;
  }
}

.blog-details .blog-details .blogs .content-post .testi:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #878244;
  width: 6px;
}

.blog-details .blog-details .blogs .content-post .testi .con {
  height: 158px;
  vertical-align: middle;
  display: table-cell;
  padding: 0 20px;
  color: #fff;
}

.blog-details .blog-details .blogs .content-post .testi .con p {
  margin: 0 0 10px;
  font-style: italic;
  color: #fff;
  position: relative;
}

.blog-details .blog-details .blogs .content-post .testi .con p:after {
  content: '\f10d';
  position: absolute;
  left: -25px;
  top: -5px;
  font-style: 18px;
  color: #878244;
  font-family: FontAwesome;
}

.blog-details .blog-details .admin {
  display: block;
  margin: 0 0 70px;
}

@media only screen and (max-width: 768px) {
  .blog-details .blog-details .admin {
    margin: 0 0 40px;
  }
}

.blog-details .blog-details .admin .dbox .floatleft {
  width: 70%;
  display: block;
  background: #111;
}

@media only screen and (max-width: 320px) {
  .blog-details .blog-details .admin .dbox .floatleft {
    width: 100%;
  }
}

.blog-details .blog-details .admin .dbox .floatleft .content {
  height: 210px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 50px;
  position: relative;
}

@media only screen and (max-width: 320px) {
  .blog-details .blog-details .admin .dbox .floatleft .content {
    padding: 30px;
  }
}

.blog-details .blog-details .admin .dbox .floatleft .content:after {
  content: '';
  position: absolute;
  left: 0;
  top: 42px;
  bottom: 42px;
  width: 6px;
  background: #878244;
}

.blog-details .blog-details .admin .dbox .floatleft .content h4 {
  font-family: "Audiowide", cursive;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  margin: 0 0 10px;
}

.lst_div_box_galery {
  position: absolute;
  top: -322px;
}

.blog-details .blog-details .admin .dbox .floatleft .content p {

  margin: 0 0 10px;
}

.blog-details .blog-details .admin .dbox .floatleft .content ul {
  display: inline-block;
  position: relative;
  line-height: 34px;
  padding: 0 20px;
  background: #878244;
}

.blog-details .blog-details .admin .dbox .floatleft .content ul:after {
  content: '';
  position: absolute;
  right: -12px;
  width: 12px;
  top: 0;
  bottom: 0;
  border-left: 12px solid #878244;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}

.blog-details .blog-details .admin .dbox .floatleft .content ul li {
  display: inline-block;
}

.blog-details .blog-details .admin .dbox .floatleft .content ul li a {
  display: block;
  padding: 0 5px;
  color: #fff;
}

.blog-details .blog-details .admin .dbox .floatright {
  width: 30%;
  background: #111;
  display: block;
  text-align: right;
  height: 210px;
}

@media only screen and (max-width: 320px) {
  .blog-details .blog-details .admin .dbox .floatright {
    display: none;
  }

  .about-area .about-contents .btn2 {
    display: block;
    width: 145px;
    margin-left: 0;
  }
}

.blog-details .blog-details .comments-area {
  margin: 0 0 60px;
}

@media only screen and (max-width: 768px) {
  .blog-details .blog-details .comments-area {
    margin: 0 0 40px;
  }
}

.blog-details .blog-details .comments-area h3 {
  font-family: "Audiowide", cursive;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  position: relative;
  padding: 0 0 20px;
}

.blog-details .blog-details .comments-area h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  width: 60px;
  background: #e7e7e7;
  border-radius: 10px;
}

.blog-details .blog-details .comments-area h3 span {
  color: #878244;
}

.blog-details .blog-details .comments-area .comment-list {
  padding: 30px;
  border: 1px solid #424242;
  margin-bottom: -1px;
}

.blog-details .blog-details .comments-area .comment-list .dbox .dleft {
  padding-right: 15px;
}

.blog-details .blog-details .comments-area .comment-list .dbox .dright h5 {
  font-family: "Audiowide", cursive;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  margin: 0 0 15px;
}

.blog-details .blog-details .comments-area .comment-list .dbox .dright h5 span {
  color: #878244;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-left: 10px;
}

.blog-details .blog-details .comments-area .comment-list .dbox .dright p {
  margin: 0;
}

.blog-details .blog-details .comment-box h3 {
  font-family: "Audiowide", cursive;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  position: relative;
  padding: 0 0 20px;
}

.blog-details .blog-details .comment-box h3:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 6px;
  width: 60px;
  background: #e7e7e7;
  border-radius: 10px;
}

.blog-details .blog-details .comment-box h3 span {
  color: #878244;
}

.blog-details .blog-details .comment-box form fieldset input {
  width: 100%;
  padding: 0 15px;
  line-height: 50px;
  height: 50px;
  border: 1px solid #424242;
  background: #111;
}

.blog-details .blog-details .comment-box form fieldset textarea {
  width: 100%;
  padding: 15px;
  height: 124px;
  border: 1px solid #424242;
  background: #111;
}

/*....................................
18. Faq area start here
....................................*/
/*....................................
19. Contact area start here
....................................*/
.contact-area .map-area {
  position: relative;
  display: block;
  margin: 0 0 115px;
}

.contact-area .map-area .go-map {
  position: absolute;
  right: -25px;
  top: 54px;
  color: #fff;
  z-index: 999;
  display: inline-block;
  line-height: 55px;
  padding: 0 28px;
  z-index: 2;
  background: #878244;
}

.contact-area .map-area .go-map:hover {
  color: #fff;
}

.contact-area .map-area .go-map:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: -21px;
  width: 25px;
  height: 21px;
}

.contact-area .map-area .map-full {
  z-index: 1;
}

.contact-area .contact-info {
  height: 196px;
  border: 8px solid #424242;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 0 30px;
  position: relative;
}

.contact-area .contact-info span {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -54px;
  border: 8px solid #424242;
  width: 108px;
  height: 108px;
  background: #111;
  font-size: 28px;
  color: #fff;
  border-radius: 100%;
  line-height: 94px;
  transition: all 0.3s;
}

.contact-area .contact-info p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}

.contact-area .contact-info:hover span {
  background: #878244;
  color: #fff;
}

.contact-area .form-area form fieldset {
  display: block;
  margin: 0 0 30px;
}

.contact-area .form-area form fieldset .feld {
  position: relative;
}

.contact-area .form-area form fieldset .feld input {
  width: 100%;
  border: 1px solid #424242;
  padding: 0 15px;
  height: 50px;
  transition: all 0.3s;
  background-color: #111;
}

.contact-area .form-area form fieldset .feld input:focus {
  border: 1px solid #878244;
}

.contact-area .form-area form fieldset .feld input:focus+span {
  color: #878244;
}

.contact-area .form-area form fieldset .feld input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
}

.contact-area .form-area form fieldset .feld input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}

.contact-area .form-area form fieldset .feld input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}

.contact-area .form-area form fieldset .feld input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}

.contact-area .form-area form fieldset .feld span {
  position: absolute;
  right: 35px;
  top: 0;
  line-height: 50px;
  color: #797979;
  transition: all 0.3s;
}

.contact-area .form-area form fieldset .feld span.msg {
  right: 20px;
}

.contact-area .form-area form fieldset .feld textarea {
  width: 100%;
  border: 1px solid #424242;
  padding: 15px;
  height: 180px;
  transition: all 0.3s;
  background-color: #111;
}

.contact-area .form-area form fieldset .feld textarea:focus {
  border: 1px solid #878244;
}

.contact-area .form-area form fieldset .feld textarea:focus+span {
  color: #878244;
}

.contact-area .form-area form fieldset .feld textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}

.contact-area .form-area form fieldset .feld textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}

.contact-area .form-area form fieldset .feld textarea:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}

.contact-area .form-area form fieldset .feld textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
  text-transform: capitalize;
  color: #797979;
}


/* login_section start */

.login_section {
  width: 100%;
  float: left;
}

.login_form_wrapper {
  float: left;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.login_form_wrapper h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.login_wrapper {
  width: 100%;
  background: #111;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.login_wrapper a.btn {
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #424242;
  display: inline-block;
  position: relative;
  padding: 0 60px;
  line-height: 50px;
  height: 50px;
  background: #878244;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  clip-path: polygon(17% 0%, 96% 0, 83% 100%, 5% 100%);
}

.login_message {
  border-top: 1px solid #424242;
  padding-top: 20px;
}

.login_wrapper a.btn:hover {
  background-color: #313131;
  border-color: #111;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.login_wrapper a span {
  float: left;
}

.login_wrapper a i {
  float: right;
  margin: 0;
  line-height: 50px;
}

.login_wrapper a.google-plus {
  background: #e0594b;
  border: 1px solid #e0594b;
}

.login_wrapper a.google-plus:hover {
  background: #b9291a;
  border-color: #b9291a;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.login_wrapper h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #fff;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}

.login_wrapper h2::before,
.login_wrapper h2::after {
  content: "";
  background: #424242;
  width: 100px;
  height: 1px;
  position: absolute;
  top: 50%;
}

.login_wrapper h2::before {
  right: 60%;
}

.login_wrapper h2::after {
  left: 60%;
}

.login_wrapper .form-control {
  height: 53px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid #424242;
  border-radius: 8px;
  box-shadow: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: #111;
}

.login_wrapper textarea.form-control {
  height: auto;
  resize: none;
}

.login_wrapper input::placeholder,
.login_wrapper textarea::placeholder {
  color: #999;
}

.login_wrapper .form-control:focus {
  color: #999;
  background-color: fafafa;
  border: 1px solid #878244 !important;
}

.login_wrapper .formsix-pos,
.formsix-e {
  position: relative;
}

.login_wrapper .form-group.i-password:after,
.form-group.i-email:after {
  position: absolute;
  top: 13px;
  font-size: 16px;
  font-family: "FontAwesome";
  color: #c0c0c0;
}

.login_remember_box {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #999;
}

.login_remember_box .control {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin: 0;
}

.login_remember_box .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.login_remember_box .control__indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  background: #fff;
  border: 1px solid #999;
}

.login_remember_box .control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.login_remember_box .control input:checked~.control__indicator:after {
  display: block;
}

.login_remember_box .control--checkbox .control__indicator:after {
  left: 4px;
  top: 0;
  width: 5px;
  height: 10px;
  border: solid #111;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.login_remember_box .forget_password {
  float: right;
  color: #db4c3e;
  font-size: 14px;
  text-decoration: underline;
}

.login_btn_wrapper {
  padding-bottom: 20px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.login_btn_wrapper a.login_btn:hover {
  background-color: #313131;
  border-color: #111;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.login_message p {
  text-align: center;
  font-size: 16px !important;
  margin: 0;
}

.login_message a {
  color: #878244;
  font-weight: 500;
}

.login_form_wrapper p {
  width: 70%;
  text-transform: capitalize;
  text-align: center;
  margin: 0px auto;
  font-size: 14px;
}

/* login_section end */
/* register section start */
.register_section {
  float: left;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.register_left_form {
  float: left;
  width: 100%;
}

.register_left_form .column {
  padding-right: 30px;
}

.register_left_form .form-group {
  position: relative;
  margin-bottom: 5px;
}

.register_left_form .field-label i {
  float: right;
  font-size: 16px;
  color: #666;
}

.register_left_form .form-group .text {
  position: relative;
  color: #bbbbbb;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 5px;
}

.register_left_form input[type="text"],
.register_left_form input[type="email"],
.register_left_form input[type="password"],
.register_left_form input[type="tel"],
.register_left_form input[type="number"],
.register_left_form input[type="url"],
.register_left_form select,
.register_left_form textarea {
  position: relative;
  display: block;
  width: 100%;
  background-color: #111;
  text-transform: capitalize;
  font-size: 15px;
  line-height: 26px;
  color: #888888;
  padding: 12px 15px;
  border-radius: 7px;
  height: 50px;
  margin-bottom: 15px;
  border: 1px solid #424242;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.register_left_form textarea {
  resize: none;
  height: 60px !important;
  padding-left: 30px;
}

.register_left_form input:focus,
.register_left_form select:focus,
.register_left_form textarea:focus {
  border-color: #878244;
}

.jp_regiter_top_heading p {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-top: 10px;
}

.check-box {
  margin-top: 35px;
}

.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
}

.register_wrapper_btn {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.label_2 {
  font-weight: 400;
}

.check_box_anchr {
  color: #908a45 !important;
  ;
}

.checkout-page .check-box label {
  position: relative;
  top: -1px;
  font-weight: normal;
  padding: 0px;
  font-size: 18px;
  cursor: pointer;
  color: #333333;
}

.register_left_form input[type="checkbox"]+label:before {
  display: none;
}

.register_btn_wrapper {
  padding-top: 15px;
  padding-bottom: 20px;
}

.btm_txt_register_form {
  width: 70%;
  text-align: center;
  margin: 0px auto;
  font-size: 14px;
}

.register_wrapper_box {
  float: left;
  width: 100%;
  background: #111;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* register_section end */

/*....................................
20. Footer area start here
....................................*/
footer {
  position: relative;
  display: block;
  background-size: cover;
  padding-top: 75px;
  width: 100%;
  float: left;
}

footer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
}

footer .footer-bottom {
  display: block;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  line-height: 80px;
  height: 80px;
  z-index: 999;
}

@media only screen and (max-width: 992px) {
  footer .footer-bottom {
    height: auto;
    line-height: 26px;
    padding: 30px 0;
  }
}

footer .footer-bottom .copyright p {
  color: #878787;
  margin: 0;
}

@media only screen and (max-width: 992px) {
  footer .footer-bottom .copyright p {
    margin: 0 0 15px;
  }
}

footer .footer-bottom .copyright p span {
  color: #878244;
}

footer .footer-bottom .foo-links {
  text-align: right;
}

footer .footer-bottom .foo-links ul li {
  display: inline-block;
  padding: 0 16px;
}

@media only screen and (max-width: 480px) {
  footer .footer-bottom .foo-links ul li {
    padding: 0 6px;
  }
}

footer .footer-bottom .foo-links ul li a {
  display: block;
  color: #797979;
  text-transform: uppercase;
  font-size: 14px;
}

footer .footer-bottom .foo-links ul li a:hover {
  color: #fff;
}

footer .footer-bottom .foo-links ul li:first-child {
  padding-left: 0;
}

footer .footer-bottom .foo-links ul li:last-child {
  padding-right: 0;
}

footer .footer-top {
  position: relative;
  z-index: 999;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

footer .footer-top .foo-about figure {
  position: relative;
  display: block;
  margin: 0 0 30px;
  padding: 0;
}

footer .footer-top .foo-about .contents {
  position: relative;
  display: block;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 0 30px;
  margin-right: 50px;
}

footer .footer-top .foo-about .contents p {
  display: block;
  margin: 0 0 30px;
}

footer .footer-top .foo-about .contents a:hover {
  color: #fff;
}

footer .footer-top h2 {
  font-size: 22px;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  position: relative;
  display: block;
  padding-left: 70px;
  margin: 50px 0 70px;
}

footer .footer-top h2:after {
  content: '';
  position: absolute;
  left: 0;
  top: -2px;
  bottom: 0;
  width: 50px;
  height: 28px;
  background: #878244;
  -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
}

footer .footer-top .foo-news .newslists {
  display: block;
  padding: 25px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

footer .footer-top .foo-news .newslists:first-child {
  padding-top: 0;
}

footer .footer-top .foo-news .newslists:last-child {
  padding-bottom: 0;
  border: none;
}

footer .footer-top .foo-news .newslists .dbox .dleft figure {
  display: block;
  width: 80px;
  position: relative;
  margin: 0;
  padding: 0;
}

footer .footer-top .foo-news .newslists .dbox .dleft figure img {
  width: 100%;
  display: block;
}

footer .footer-top .foo-news .newslists .dbox .dright .content h4 {
  font-weight: 400;
  font-family: "Audiowide", cursive;
  font-size: 18px;
  display: block;
  margin: 0 0 5px;
}

footer .footer-top .foo-news .newslists .dbox .dright .content h4 a {
  color: #fff;
  display: inline-block;
}

footer .footer-top .foo-news .newslists .dbox .dright .content h4 a:hover {
  color: #878244;
}

footer .footer-top .foo-news .newslists .dbox .dright .content p {
  display: block;
  margin: 0 0 5px;
}

footer .footer-top .foo-news .newslists .dbox .dright .content span {
  color: #878244;
}

footer .footer-top .products-foo ul {
  display: block;
  margin: 0 0 25px;
}

footer .footer-top .products-foo ul li {
  display: inline-block;
  padding: 0 4px;
}

footer .footer-top .products-foo ul li:first-child {
  padding-left: 0;
}

footer .footer-top .products-foo ul li:last-child {
  padding-right: 0;
}

footer .footer-top .products-foo ul li a {
  display: block;
  width: 70px;
}

footer .footer-top .products-foo ul li img {
  display: block;
  width: 100%;
}

/*....................................
21. Gallery area start here
....................................*/
.gallery-area .gimg {
  display: block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 25px;
}

.gallery-area .gimg figure {
  display: block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  margin: 0;
}

.gallery-area .gimg figure img {
  width: 100%;
  transition: all 0.3s;
}

.gallery-area .gimg figure .content {
  position: absolute;
  top: 58px;
  left: 0;
  right: 35px;
  z-index: 999;
  opacity: 0;
  transition: all 0.3s;
}

.gallery-area .gimg figure .content h3 {
  font-size: 22px;
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  margin: 0 0 10px;
  padding-left: 70px;
  position: relative;
}

.gallery-area .gimg figure .content h3:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  background: #878244;
  -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);
}

.gallery-area .gimg figure .content p {
  margin: 0;
  padding-left: 70px;
  color: #fff;
}

.gallery-area .gimg figure:after {
  content: '';
  position: absolute;
  left: 100%;
  top: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
  transition: all 0.3s;
}

.gallery-area .gimg .con-pop {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}

.gallery-area .gimg .con-pop span {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  line-height: 150px;
  color: #fff;
  background: #878244;
  z-index: 999;
  display: inline-block;
  transition: all 0.3s;
  -webkit-clip-path: polygon(100% 100%, 100% 100%, 100% 100%);
  clip-path: polygon(100% 100%, 100% 100%, 100% 100%);
}

.gallery-area .gimg .con-pop span i {
  padding-right: 20px;
}

.gallery-area .gimg:hover figure img {
  transform: scale(1.1, 1.1);
}

.gallery-area .gimg:hover figure .content {
  opacity: 1;
}

.gallery-area .gimg:hover figure:after {
  left: 0;
  top: 0;
}

.gallery-area .gimg:hover .con-pop span {
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

/*....................................
22. Event area start here
....................................*/
.events-area .panel-group {
  margin-bottom: 0;
}

.events-area .panel-group .panel {
  border-color: #424242;
  border-radius: 0;
  margin-top: 20px;
  box-shadow: none;
}

.events-area .panel-group .panel:first-child {
  margin-top: 0;
}

.events-area .panel-group .panel .panel-heading {
  background: #111;
  border: none;
  padding: 50px 50px 30px;
  border-radius: 0;
}

@media only screen and (max-width: 992px) {
  .events-area .panel-group .panel .panel-heading {
    padding-right: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .events-area .panel-group .panel .panel-heading {
    padding: 30px;
    padding-right: 10px;
  }
}

.events-area .panel-group .panel .panel-heading a {
  position: relative;
  display: block;
  background: #111;
}

.events-area .panel-group .panel .panel-heading a h3 {
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #fff;
  margin: 0 0 15px;
}

.events-area .panel-group .panel .panel-heading a ul li {
  display: inline-block;
  padding-right: 35px;
}

@media only screen and (max-width: 480px) {
  .events-area .panel-group .panel .panel-heading a ul li {
    padding-right: 20px;
  }
}

.events-area .panel-group .panel .panel-heading a ul li i {
  color: #fff;
  padding-right: 8px;
}

.events-area .panel-group .panel .panel-heading a ul li span {
  color: #fff;
}

.events-area .panel-group .panel .panel-heading a ul li:last-child {
  padding-right: 0;
}

.events-area .panel-group .panel .panel-heading a .arrows {
  position: absolute;
  right: 0;
  top: -10px;
  font-family: "Audiowide", cursive;
  color: #878244;
  font-size: 80px;
  display: inline-block;
  line-height: 60px;
  transition: all 0.3s;
}

@media only screen and (max-width: 992px) {
  .events-area .panel-group .panel .panel-heading a .arrows {
    font-size: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .events-area .panel-group .panel .panel-heading a .arrows {
    font-size: 36px;
    opacity: 0.5;
  }
}

.events-area .panel-group .panel .panel-heading a .arrows:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  height: 20px;
  width: 80px;
  margin: 0 auto;
  background: #878244;
  -webkit-clip-path: polygon(50% 0, 100% 100%, 50% 20%, 0% 100%);
  clip-path: polygon(50% 0, 100% 100%, 50% 20%, 0% 100%);
  transition: all 0.3s;
}

@media only screen and (max-width: 480px) {
  .events-area .panel-group .panel .panel-heading a .arrows:after {
    width: 40px;
    height: 10px;
    bottom: 0;
  }
}

.events-area .panel-group .panel .panel-heading a.btncnc .arrows {
  position: absolute;
  right: 0;
  top: -10px;
  font-family: "Audiowide", cursive;
  color: #878244;
  font-size: 80px;
  display: inline-block;
  line-height: 60px;
  transition: all 0.3s;
}

@media only screen and (max-width: 992px) {
  .events-area .panel-group .panel .panel-heading a.btncnc .arrows {
    font-size: 60px;
  }
}

.events-area .panel-group .panel .panel-heading a.btncnc .arrows:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  height: 20px;
  width: 80px;
  margin: 0 auto;
  background: #878244;
  -webkit-clip-path: polygon(50% 80%, 100% 0, 50% 100%, 0 0);
  clip-path: polygon(50% 80%, 100% 0, 50% 100%, 0 0);
  transition: all 0.3s;
}

.events-area .panel-group .panel .panel-heading a.collapsed h3 {
  font-family: "Audiowide", cursive;
  font-weight: 400;
  color: #878244;
  margin: 0 0 10px;
}

.events-area .panel-group .panel .panel-heading a.collapsed .arrows {
  position: absolute;
  right: 0;
  top: -10px;
  font-family: "Audiowide", cursive;
  color: #eee;
  font-size: 80px;
  display: inline-block;
  line-height: 60px;
  transition: all 0.3s;
}

@media only screen and (max-width: 992px) {
  .events-area .panel-group .panel .panel-heading a.collapsed .arrows {
    font-size: 60px;
  }
}

.events-area .panel-group .panel .panel-heading a.collapsed .arrows:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -25px;
  height: 20px;
  width: 80px;
  margin: 0 auto;
  background: #eee;
  -webkit-clip-path: polygon(50% 80%, 100% 0, 50% 100%, 0 0);
  clip-path: polygon(50% 80%, 100% 0, 50% 100%, 0 0);
  transition: all 0.3s;
}

.events-area .panel-group .panel .panel-collapse .panel-body {
  border-top: none;
  padding: 0 200px 50px 50px;
  background-color: #111;
}

@media only screen and (max-width: 992px) {
  .events-area .panel-group .panel .panel-collapse .panel-body {
    padding-right: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .events-area .panel-group .panel .panel-collapse .panel-body {
    padding: 30px;
    padding-top: 0px;
  }
}

.events-area .panel-group .panel .panel-collapse .panel-body p {
  color: #fff;
}

.events-area .panel-group .panel .panel-collapse .panel-body .events-img {
  margin: 0 -15px;
}

.events-area .panel-group .panel .panel-collapse .panel-body .events-img .owl-item {
  display: block;
  position: relative;
  padding: 0 15px;
}

.events-area .panel-group .panel .panel-collapse .panel-body .events-img .owl-item img {
  width: 100%;
  display: block;
}

.event-details .buyticket {
  display: block;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .event-details .buyticket {
    margin: 0 0 50px;
  }
}

.event-details .buyticket figure {
  display: block;
  position: relative;
}

.event-details .buyticket figure img {
  width: 100%;
}

.event-details .buyticket .con {
  display: block;
  position: relative;
  border: 1px solid #424242;
  padding: 40px;
  border-top: none;
}

.event-details .buyticket .con p {
  margin: 0 0 5px;
  color: #fff;
  display: inline-block;
}

.event-details .buyticket .con p:first-child {
  margin: 0 0 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.event-details .buyticket .con strong {
  font-family: "Audiowide", cursive;
  display: inline-block;
  color: #878244;
  padding-left: 10px;
  font-size: 18px;
}

.event-details .buyticket .con a {
  padding: 0 30px;
  margin-top: 25px;
}

.event-details .descriptions .eventleft {
  margin: 0 0 30px;
  display: block;
  position: relative;
}

.event-details .descriptions .eventleft li {
  text-align: center;
  display: inline-block;
  background: #878244;
  padding: 14px 0;
  height: 80px;
  width: 100px;
  -webkit-clip-path: polygon(12% 0, 100% 0, 88% 100%, 0% 100%);
  clip-path: polygon(12% 0, 100% 0, 88% 100%, 0% 100%);
}

@media only screen and (max-width: 480px) {
  .event-details .descriptions .eventleft li {
    margin: 5px 3px;
  }

  .about-area .about-contents .buttons .btn1 {
    clip-path: none;
  }

  .about-area .about-contents .btn2 {
    clip-path: none;
    display: block;
    width: 204px;
    margin-left: 0;
  }

  .about-area .about-contents .btn2:after {
    display: none;
  }
}

.event-details .descriptions .eventleft li span {
  color: #fff;
  font-family: "Audiowide", cursive;
  font-size: 20px;
  display: block;
}

.event-details .descriptions .eventleft li p {
  margin: 0;
  color: #fff;
  opacity: 0.8;
}

.event-manegment {
  position: relative;
  display: block;
  background: #111;
}

.event-manegment .speakers-slider {
  margin: 0 -10px -10px;
}

.event-manegment .speakers-slider .sp-list {
  margin: 10px;
  display: block;
  transition: all 0.3s;
}

.event-manegment .speakers-slider .sp-list figure {
  position: relative;
  display: block;
  position: relative;
}

.event-manegment .speakers-slider .sp-list figure img {
  display: block;
  width: 100%;
}

.event-manegment .speakers-slider .sp-list .con {
  text-align: center;
}

.event-manegment .speakers-slider .sp-list .con a {
  display: block;
  position: relative;
  background: #878244;
  color: #fff;
  font-family: "Audiowide", cursive;
  font-size: 18px;
  line-height: 50px;
}

.event-manegment .speakers-slider .sp-list:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.event-manegment .event-schedule {
  display: block;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .event-manegment .event-schedule {
    margin: 50px 0 0;
  }
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #424242;
}

.event-manegment .event-schedule table {
  margin: 0 !important;
}

.event-manegment .event-schedule table tbody tr td {
  padding: 15px 0;
}

.event-manegment .event-schedule table tbody tr td strong {
  color: #fff;
}

.event-manegment .event-schedule table tbody tr td span {
  color: #fff;
  text-transform: capitalize;
}

.event-manegment .event-schedule table tbody tr td a {
  text-transform: capitalize;
  display: inline-block;
  color: #878244;
}

.event-manegment .event-schedule table tbody tr td a:hover {
  color: #111;
}

.event-manegment .event-schedule table tbody tr td:last-child {
  text-align: right;
}

.event-manegment .event-schedule table tbody tr:first-child td {
  border-top: none !important;
}

.news_left_wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center 0;
  position: relative;
}

.main_news_right_box {
  border: 1px solid #424242;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.main_news_right_box:hover {
  border: 1px solid #878244;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.main_news_right_box:hover .news_right_box1 {
  border-bottom: 1px solid #eeeeee;
}

.news_right_box1 p {
  padding-top: 40px;
  color: #878244;
  margin-left: 40px;
  text-transform: capitalize;
}

.news_right_box1 h3 {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 20px;
  color: #fff;
  font-family: "Audiowide", cursive;
  margin-left: 40px;
}

.news_right_box1 h6 {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #fff;
  margin-left: 40px;
  font-weight: 400;
  padding-bottom: 20px;
}

.news_right_box1 {
  border-bottom: 1px solid #424242;
}

.news_botton_cont {
  margin-top: 30px;
}

.news_botton_cont p {
  margin-left: 40px;
}

.news_botton_cont h5 {
  font-size: 16px;
  font-weight: 500;
  margin-left: 40px;
  margin-top: 20px;
  padding-bottom: 8px;
}

.news_botton_cont h5 a {
  color: #878244;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.news_botton_cont h5 a:hover {
  color: #878244;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.news_botton_cont h5 i {
  color: #878244;
}

.news_left_img_overlay {
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  background: rgba(0, 0, 0, 0.5);
}

.news_left_cont p {
  color: #878244;
  padding-top: 40px;
  text-align: left;
  margin-left: 30px;
  text-transform: capitalize;
}

.news_left_cont h6 i {
  color: #878244;
  font-size: 16px;
  margin-right: 8px;
}

.heart_box a i {
  color: #fff;
}

.heart_box a {
  width: 45px;
  height: 45px;
  border: 2px solid #878244;
  float: right;
  margin-top: -63px;
  color: #fff;
  text-align: center;
  line-height: 45px;
  margin-right: 25px;
  transform: skew(-17deg);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.news_right_box1 h6 i {
  color: #878244;
  margin-right: 8px;
}

.news_left_cont h6 {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.79);
  padding-top: 210px;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  margin-left: 25px;
  margin-bottom: 15px;
}

.heart_box a:hover {
  background: #878244;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.news_left_cont h3 {
  font-size: 22px;
  color: #fff;
  font-family: "Audiowide", cursive;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 25px;
  margin-bottom: 15px;
}

.news_left_cont h5 {
  font-size: 16px;
  font-weight: 500;
  margin-left: 25px;
}

.news_left_cont h5 a {
  color: #878244;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.news_left_cont h5 a:hover {
  color: #878244;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.news_left_cont h5 i {
  color: #878244;
}

.custom_btn {
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #878244;
  display: inline-block;
  position: relative;
  padding: 0 60px;
  line-height: 50px;
  height: 50px;
  background: #878244;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}




@media (min-width: 991px) and (max-width: 1199px) {
  .lst_div_box_galery {
    position: absolute;
    top: -269px;
  }
}

@media (max-width: 991px) {
  .lst_div_box_galery {
    position: absolute;
    top: -201px;
  }

  .custom_btn {
    padding: 0 25px;
    margin-bottom: 10px;
  }

  .shop-page .filter-area {
    margin: 0px 10px 40px;
  }
}

.paginations2 {
  display: block;
  padding-top: 20px;
  width: 100%;
}

.paginations2 .media {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}

.paginations2 .media .list-inline {
  display: flex;
  column-gap: 10px;
}

.buyticket .input-group input {
  width: 60%;
}

.list-inline {
  display: flex;
  column-gap: 10px;
}

.p-inputtextarea {
  width: 100%;
}

.qty>span>input {
  max-width: 50px;
}

.contact.p-inputtext {
  width: 100%;
}