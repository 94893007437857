.home_bg {
	width: 100%;
	margin-top: 380px;
	padding: 0px;
	margin-left: 0px;
}

@media only screen and (max-width: 480px) {
	.home_bg {
		height: 540px;
	}

	.col-xs-6 {
		width: 50%;
	}

	.bg-img {
		background-size: 100% auto !important;
		padding-top: 20px;
		background-position: 0 110px !important;
	}
}

.table_details tr {
	border-bottom: 1px solid #fff;
}

.table_details tr:last-child {
	border-bottom: 0px solid #fff;
}

.p-overlay-badge .p-badge {
	min-width: 25px;
	min-height: 25px;
}

label {
	color: white !important;
}

.mobile_menu>button {
	float: right;
	margin-right: 10px;
	margin-top: 40px;
}